import { Box, Flex, Image } from '@chakra-ui/core'
import React from 'react'
import { images } from '../../../theme'
import { H3 } from '../../../typography'

type DocumentProps = {
  downloadDocument: (document: any) => void
  downloadCoach: (document: any) => void
  document: any
  setIsOpen: (isOpen: boolean) => void
}

const Document: React.FC<DocumentProps> = ({
  downloadDocument,
  downloadCoach,
  document,
  setIsOpen
}) => {
  if (!document) {
    return null
  }
  return (
    <Box
      boxShadow="0px 2px 4px 0px rgba(196, 196, 196, 0.5)"
      borderRadius="8px"
      bg="white"
      cursor="pointer"
      flexDir="column"
      p={3}
      h="44px"
      justifyContent="space-between"
      data-testid="document-item"
    >
      <Flex flexDir="row">
        <Image src={images.file} pr={2} />
        <H3
          fontSize={14}
          color="asera.500"
          fontWeight={400}
          textAlign="left"
          onClick={() => {
            //@ts-ignore
            if (document?.document) {
              //@ts-ignore
              downloadDocument(document?.document)
            } else {
              //@ts-ignore
              downloadCoach(document?.saboteurProofValue)
            }
          }}
          data-testid="download-document"
        >
          {document?.name}
        </H3>
        <div style={{ float: 'right' }}></div>
      </Flex>
      {
        //@ts-ignore
        document.document ? (
          <Image
            src={images.darkTrash}
            mt="-20px"
            float="right"
            onClick={() => setIsOpen(true)}
            data-testid="open-document"
          />
        ) : null
      }
    </Box>
  )
}

export default Document
