import { Flex, Image } from '@chakra-ui/core'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import * as React from 'react'
import { useMediaQuery } from 'react-responsive'
import { RouteComponentProps, withRouter, useHistory } from 'react-router'
import { color, ColorProps, space, SpaceProps } from 'styled-system'
import { useAppContext } from '../../context/AppProvider'
import { useNotesContext } from '../../context/NotesProvider'
import SideBarButton from '../SideBar/SideBarButton'
import { H3, Text } from '../../typography'
import { Breadcrumbs, MotionFlex, Notifications } from '..'
import { images } from '../../theme'
import { DisplayLanguage } from '../../utils/languageHelpers'
import { useGetMyNotificationsQuery, Notification } from '../../generated/graphql'
import get from 'lodash/get'
import { useAuthContext } from '../../context/AuthProvider'

export type HeaderProps = RouteComponentProps &
  ColorProps & {
    color?: string
    size?: number
    id?: string
    open?: boolean
    getLoggedInUser?: () => { name?: string; id: string }
    name?: string
    isDashboard?: boolean
    headerHeight?: string
  }

type HeaderContProps = SpaceProps &
  ColorProps & {
    color?: string
    open?: boolean
    isDashboard: boolean
    headerHeight?: string
  }

const HeaderCont = styled(motion.div)<HeaderContProps>`
  ${space};
  ${color};
  backgound-color: red;
  top: 0;
  right: 0;
  height: 64px;
  z-index: 1000;
  display: flex;
  position: fixed;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;
  border-bottom-width: 1px;
  justify-content: flex-start;
  left: 205px;
  @media screen and (max-width: 40em) {
    left: 0;
    height: ${(props) => (props.headerHeight ? props.headerHeight : '70px')};
  }
  width: auto;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
`

const Header: React.FC<HeaderProps> = ({ name, isDashboard, ...rest }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 40em)' })
  const isOnboarding = window.location.pathname.includes('onboarding')
  const { location } = useHistory()
  const { user } = useAuthContext()
  const {
    drawerOpen,
    toggleDrawer,
    article,
    setArticlesFunction,
    unsetArticlesFunction,
    toggleDocuments,
    toggleNotes,
    toggleReports,
    mywork,
    setNotiticationCount
  } = useAppContext()
  const { openNotes, setOpenNotes } = useNotesContext()

  const { data } = useGetMyNotificationsQuery({
    variables: {
      where: { users: user?.id }
    },
    fetchPolicy: 'network-only'
  })

  const notifications = get(data, 'notifications') as Notification[]
  const unreadNotifications = notifications?.filter((n) => !n?.read)

  const hasNew = notifications?.some((i) => i?.read !== true)

  React.useEffect(() => {
    setNotiticationCount(unreadNotifications?.length)
  }, [hasNew, unreadNotifications, setNotiticationCount])

  const NotesBarButton = React.memo(() => (
    <MotionFlex className="notepadTour">
      <Image
        src={images.notepadPen}
        color="#FFFFFF"
        size="20px"
        cursor="pointer"
        onClick={() => setOpenNotes(true)}
      />
      {!isTabletOrMobile && (
        <H3
          ml={3}
          color="#fff"
          fontSize="16px"
          style={{ cursor: 'pointer' }}
          onClick={() => setOpenNotes(true)}
        >
          {DisplayLanguage('dashboardScreen', 'notepadText') || 'Notepad'}
        </H3>
      )}
    </MotionFlex>
  ))

  const MobileHeader = () => (
    <>
      <SideBarButton color="#FFFFFF" open={drawerOpen} onClick={toggleDrawer} />
      <Text color="#FFFFFF" fontSize="14px" fontWeight={600} textAlign="start" ml="-40px">
        {name}
      </Text>
    </>
  )

  function renderHeader() {
    switch (location.pathname) {
      case '/auth/mylibrary':
        return !isOnboarding ? (
          <HeaderCont
            data-testid="header-name"
            {...rest}
            bg="lucaniaBlue.500"
            isDashboard={isDashboard || false}
            headerHeight={isTabletOrMobile ? '120px' : '70px'}
            pl={drawerOpen ? '64px' : '0px'}
          >
            {isTabletOrMobile ? (
              <Flex flexDir="column" width="100%" align="center" justify="center">
                <Flex
                  flexDir="row"
                  justifyContent="space-between"
                  align="center"
                  width="100%"
                  h="70px"
                  px="16px"
                >
                  {!openNotes && <MobileHeader />}
                  <NotesBarButton />
                </Flex>
                <Flex
                  flexDir="row"
                  justifyContent="space-between"
                  align="center"
                  width="100%"
                  bottom="0px"
                  h="48px"
                >
                  <Flex
                    color="white"
                    borderBottom={article ? '4px solid #F4A807' : null}
                    h="100%"
                    width="100%"
                    justifyContent="center"
                    alignItems="center"
                    onClick={setArticlesFunction}
                  >
                    <Text fontSize="16px" fontWeight={600}>
                      Articles
                    </Text>
                  </Flex>
                  <Flex
                    color="white"
                    h="100%"
                    width="100%"
                    justifyContent="center"
                    alignItems="center"
                    borderBottom={!article ? '4px solid #F4A807' : null}
                    onClick={unsetArticlesFunction}
                  >
                    <Text fontSize="16px" fontWeight={600} textAlign={'center'}>
                      Favourites
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            ) : (
              <Flex
                flexDir="row"
                justifyContent="space-between"
                align="center"
                width="100%"
                zIndex={1}
                pr="64px"
              >
                <Text color="#FFFFFF" fontSize="14px" fontWeight={600}>
                  <Breadcrumbs />
                </Text>
                <Flex alignItems="center">
                  <Notifications />
                  <NotesBarButton />
                </Flex>
              </Flex>
            )}
          </HeaderCont>
        ) : null
      case '/auth/programmes':
        return !isOnboarding ? (
          <HeaderCont
            data-testid="header-name"
            pr={4}
            {...rest}
            bg="lucaniaBlue.500"
            pl={drawerOpen ? ' 64px' : '1rem'}
            isDashboard={isDashboard || false}
          >
            {isTabletOrMobile ? (
              <Flex flexDir="column" width="100%" align="center" justify="center">
                <Flex
                  flexDir="row"
                  justifyContent="space-between"
                  align="center"
                  width="100%"
                  my="29px"
                  h="22px"
                >
                  {!openNotes && <MobileHeader />}
                  <NotesBarButton />
                </Flex>
              </Flex>
            ) : (
              <Flex
                flexDir="row"
                justifyContent="space-between"
                align="center"
                width="100%"
                zIndex={1}
                pr="64px"
              >
                <Text color="#FFFFFF" fontSize="14px" fontWeight={600}>
                  <Breadcrumbs />
                </Text>
                <Flex alignItems="center">
                  <Notifications />
                  <NotesBarButton />
                </Flex>
              </Flex>
            )}
          </HeaderCont>
        ) : null
      case '/auth/mywork':
        return !isOnboarding ? (
          <HeaderCont
            data-testid="header-name"
            pl={drawerOpen ? '64px' : '0px'}
            {...rest}
            bg="lucaniaBlue.500"
            headerHeight={isTabletOrMobile ? '120px' : '70px'}
            isDashboard={isDashboard || false}
          >
            {isTabletOrMobile ? (
              <Flex flexDir="column" width="100%" align="center" justify="center">
                <Flex
                  flexDir="row"
                  justifyContent="space-between"
                  align="center"
                  width="100%"
                  h="70px"
                  px="16px"
                >
                  {!openNotes && <MobileHeader />}
                  <NotesBarButton />
                </Flex>
                <Flex
                  flexDir="row"
                  justifyContent="space-between"
                  align="center"
                  width="100%"
                  bottom="0px"
                  h="48px"
                >
                  <Flex
                    color="white"
                    borderBottom={mywork === 'report' ? '4px solid #F4A807' : null}
                    h="100%"
                    width="100%"
                    align="center"
                    onClick={toggleReports}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Text fontSize="16px" fontWeight={600}>
                      Reports
                    </Text>
                  </Flex>
                  <Flex
                    color="white"
                    borderBottom={mywork === 'notes' ? '4px solid #F4A807' : null}
                    h="100%"
                    width="100%"
                    align="center"
                    justifyContent="center"
                    alignItems="center"
                    onClick={toggleNotes}
                  >
                    <Text fontSize="16px" fontWeight={600}>
                      My Notes
                    </Text>
                  </Flex>
                  <Flex
                    color="white"
                    h="100%"
                    width="100%"
                    justifyContent="center"
                    alignItems="center"
                    borderBottom={mywork === 'document' ? '4px solid #F4A807' : null}
                    onClick={toggleDocuments}
                  >
                    <Text fontSize="16px" fontWeight={600}>
                      Documents
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            ) : (
              <Flex
                flexDir="row"
                justifyContent="space-between"
                align="center"
                width="100%"
                zIndex={1}
                pr="64px"
              >
                <Text color="#FFFFFF" fontSize="14px" fontWeight={600}>
                  <Breadcrumbs />
                </Text>
                <Flex alignItems="center">
                  <Notifications />
                  <NotesBarButton />
                </Flex>
              </Flex>
            )}
          </HeaderCont>
        ) : null
      case '/auth/dashboard':
        return !isOnboarding ? (
          <HeaderCont
            data-testid="header-name"
            pr={4}
            pl={drawerOpen ? '64px' : '1rem'}
            {...rest}
            bg="lucaniaBlue.500"
            isDashboard={isDashboard || false}
          >
            {isTabletOrMobile ? (
              <Flex flexDir="column" width="100%" align="center" justify="center">
                <Flex
                  flexDir="row"
                  justifyContent="space-between"
                  align="center"
                  width="100%"
                  my="29px"
                  h="22px"
                >
                  {!openNotes && <MobileHeader />}
                  <NotesBarButton />
                </Flex>
              </Flex>
            ) : (
              <Flex
                flexDir="row"
                justifyContent="space-between"
                align="center"
                width="100%"
                zIndex={1}
                pr="64px"
              >
                <Text color="#FFFFFF" fontSize="14px" fontWeight={600}>
                  <Breadcrumbs />
                </Text>
                <Flex alignItems="center">
                  <Notifications />
                  <NotesBarButton />
                </Flex>
              </Flex>
            )}
          </HeaderCont>
        ) : null

      default:
        return !isOnboarding ? (
          <HeaderCont
            data-testid="header-name"
            pr={4}
            pl={drawerOpen ? '64px' : '1rem'}
            {...rest}
            bg="lucaniaBlue.500"
            isDashboard={isDashboard || false}
          >
            {isTabletOrMobile ? (
              <Flex flexDir="column" width="100%" align="center" justify="center">
                <Flex
                  flexDir="row"
                  justifyContent="space-between"
                  align="center"
                  width="100%"
                  my="29px"
                  h="22px"
                >
                  {!openNotes && <MobileHeader />}
                  <NotesBarButton />
                </Flex>
                {/* <Flex
                  flexDir="row"
                  justifyContent="space-between"
                  align="center"
                  width="100%"
                  bottom="0px"
                  h="48px"
                >
                  <Flex
                    color="white"
                    borderBottom={'2px solid #F4A807'}
                    h="100%"
                    width="100%"
                    align="center"
                  >
                    <Link to="/auth/dashboard">
                      <Text fontSize="16px" fontWeight={600}>
                        Dashboard
                      </Text>
                    </Link>
                  </Flex>
                  <Flex color="white" h="100%" width="100%" align="center">
                    <Link to="/auth/dashboard">
                      <Text fontSize="16px" fontWeight={600}>
                        Calendar
                      </Text>
                    </Link>
                  </Flex>
                </Flex> */}
              </Flex>
            ) : (
              <Flex
                flexDir="row"
                justifyContent="space-between"
                align="center"
                width="100%"
                zIndex={1}
                pr="64px"
              >
                <Text color="#FFFFFF" fontSize="14px" fontWeight={600}>
                  <Breadcrumbs />
                </Text>
                <Flex alignItems="center">
                  <Notifications />
                  <NotesBarButton />
                </Flex>
              </Flex>
            )}
          </HeaderCont>
        ) : null
    }
  }
  return renderHeader()
}

export default withRouter(Header)

Header.defaultProps = {
  bg: 'white'
}
