import useSWR from 'swr/immutable'
import { SubModuleSubmission } from '../generated/graphql'
import { requestGet } from '../utils/services'

const useSubmoduleSubmission = (subModuleSubmissionId?: string) => {
  const submoduleSubmissionKey = subModuleSubmissionId
    ? `/sub-module-submissions/${subModuleSubmissionId}`
    : null
  const { data, mutate, isValidating } = useSWR(
    submoduleSubmissionKey,
    (url: string) => requestGet(url),
    { revalidateOnFocus: false }
  )

  if (data) {
    return {
      submoduleSubmission: data as SubModuleSubmission,
      updateSubmission: () => mutate(),
      isLoading: isValidating
    }
  }
  return {
    submoduleSubmission: {} as SubModuleSubmission,
    updateSubmission: () => mutate(),
    isLoading: isValidating
  }
}

export default useSubmoduleSubmission
