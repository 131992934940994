import useSWR from 'swr'
import { requestGet } from '../utils/services'

const useUserProgramme = (programmeId?: string) => {
  const userProgrammeKey = programmeId ? `/user-programme/${programmeId}` : null
  const { data, mutate } = useSWR(userProgrammeKey, (url: string) => requestGet(url), {
    revalidateOnFocus: false
  })

  if (data) {
    return {
      userProgramme: data,
      updateProgramme: () => mutate(),
      isLoading: false
    }
  }
  return {
    userProgramme: data,
    updateProgramme: () => mutate(),
    isLoading: !data
  }
}

export default useUserProgramme
