import { Flex, FormControl, FormLabel, Input, InputGroup, InputRightElement } from '@chakra-ui/core'
import { InputProps } from '@chakra-ui/core/dist/Input'
import { useField } from 'formik'
import { Markup } from 'interweave'
import * as React from 'react'
import { AlertCircle } from 'react-feather'
import { Text } from '../../../typography'
import { LabelProps } from '../styles'

export type ConnectedFormGroupProps = LabelProps &
  InputProps & {
    label?: string
    name: string
    lableWeight?: number
  }

const ConnectedFormGroup: React.FC<ConnectedFormGroupProps> = ({ label, ...rest }) => {
  const [field, meta] = useField(rest.name)
  const isError = meta.touched && meta.error
  return (
    <Flex
      flexDirection="column"
      width="100%"
      mr={rest.mr}
      ml={rest.ml}
      mt={rest.mt}
      mb={meta.touched && meta.error ? 0 : rest.mb}
    >
      <FormControl mb={rest.mb}>
        {label && (
          <FormLabel htmlFor={field.name} fontSize={rest.fontSize} fontWeight={rest.lableWeight}>
            <Markup content={label} />
          </FormLabel>
        )}
        <InputGroup size="md" pr={-4}>
          <Input focusBorderColor="accent.500" {...field} id={field.name} {...rest} mb={2} mr={3} />
          {meta.touched && meta.error && (
            <InputRightElement children={<AlertCircle color="red" size="15px" />} />
          )}
        </InputGroup>
        {isError ? (
          <Flex>
            <Text color="red.500" textAlign="center" fontSize="12px" margin="auto">
              {meta.error}
            </Text>
          </Flex>
        ) : null}
      </FormControl>
    </Flex>
  )
}

export default ConnectedFormGroup

ConnectedFormGroup.defaultProps = {
  mb: 2,
  type: 'text'
}
