import { ToastContent } from 'react-toastify'
import { ApolloError } from 'apollo-client'
import { isObject, omit, orderBy, transform } from 'lodash'
import get from 'lodash/get'
import { ReactNode } from 'react'
import { ERROR_TOAST, STRAPI_USER_STORAGE_KEY, SUCCESS_TOAST } from '../constants'
import { UploadFile } from '../generated/graphql'
import { format } from 'date-fns'
/**
 * Gets JWT of authenticatedUser from either sessionStorage or localStorage
 */
export const fetchJwt = () => {
  const localUser = localStorage.getItem(STRAPI_USER_STORAGE_KEY)
  const sessionUser = sessionStorage.getItem(STRAPI_USER_STORAGE_KEY)
  const user = sessionUser || localUser

  return user ? JSON.parse(user).jwt : null
}

export function formatError({ response }: any) {
  return get(response, 'data.message[0].messages[0].message', 'An unknown error has occured.')
}

export function formatGqlError(error: ApolloError) {
  return error.message.replace('GraphQL error:', '').trim()
}

export function getLanguageId(userData: any) {
  const languageId = userData?.user?.language.id
  return languageId
}

/**
 * function to return a percentage given a
 * @param message optional *user friendly* message. Recommended
 * not using the graphQL error message.
 * recommended usage within query or mutation
 * onError: (error) => error && errorToast(toast)
 */
export const errorToast = (
  toast: (props: ToastContent) => ReactNode | void,
  message?: string | undefined
): ReactNode | void => {
  return toast({ description: message || 'Oops, something went wrong!', ...ERROR_TOAST })
}

/**
 * function to return a percentage given a
 * @param message optional *user friendly* message.
 * recommended usage within query or mutation
 * onCompleted: (error) => error && onCompletedToast(toast)
 */
export const completedToast = (
  toast: (props: ToastContent) => ReactNode | void,
  message?: string | undefined
): ReactNode | void => {
  return toast({ description: message || 'Action completed successfully!', ...SUCCESS_TOAST })
}

// (alias) function toast(content: ToastContent, options?: ToastOptions<{}> | undefined
/**
 * Helper function to recuresively remove a given key from an array of objects
 */
export function omitDeep(arr: any, keyToRemove: string) {
  return arr.map((obj: any) => {
    return transform(obj, function (result: any, value, key) {
      result[key] = isObject(value) && keyToRemove in value ? omit(value, keyToRemove) : value
    })
  })
}

/**
 * Helper function to enable download of a file
 */

export const downloadFile = async (file: UploadFile, fileName: string) => {
  if (file?.url) {
    const link = document.createElement('a')
    link.href = file?.url
    link.setAttribute('target', '_blank')
    link.download = fileName
    document.body.appendChild(link)
    link.click()
  }
}

/**
 * Helper function to enable regex for sequence letters or numbers
 */

export const sequence = (value: string) => {
  const re = /abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|012|123|234|345|456|567|678|789|qwerty/gi
  if (re.test(value)) return false
  return true
}

/**
 * Helper function to enable sending of a toast when a section is complete
 */

export const sendSectionToast = (
  currentProgress: number,
  toast: (props: ToastContent) => ReactNode | void
) => {
  if (currentProgress !== 100) {
    return completedToast && completedToast(toast, 'Section complete!')
  }
}

export const formattedDate = (date: string, dateFormat = 'MM/dd/yyyy') => {
  let dateToFormat = new Date()
  if (date) {
    dateToFormat = new Date(date)
  }
  return format(
    new Date(dateToFormat.getFullYear(), dateToFormat.getMonth(), dateToFormat.getDate()),
    dateFormat
  )
}

export const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const doubleDigitTime = (timeInMilliseconds: number)  => {
  if (timeInMilliseconds) {
    return new Date(timeInMilliseconds).toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
    })
  }
  return undefined
}

export const sectionProgress = (section: any) => section.subModuleSubmission.section.find((submissionSection: any) => submissionSection.section.id === section.id).progress

export const submoduleProgress = (sections: any) => sections.length > 0 ? Math.round(
  sections.reduce(
    (acc: number, section: any) => acc + sectionProgress(section) || 0, 0
  ) / sections.length) : 0

export const downloadDocument = async (name: string, url: string) => {
    if (url) {
      const myUrl: string = url
      const fileName: string = name + ' report'
      const link = document.createElement('a')
      link.href = myUrl
      link.setAttribute('target', '_blank')
      link.download = fileName
      document.body.appendChild(link)
      link.click()
    }
}
  
export const groupProgrammeReports = (programmeReports: any) => {
  let programmes = (programmeReports || [])
    .filter((programmeReport: any) => programmeReport.programme.programme)
  if (programmes.length > 0) {
    const programmeTitles = Array.from(
      new Set(programmes.map((programmeReport: any) => programmeReport.programme.programme.name))
    ) as string[]
    programmes = programmeTitles.map((programmeTitle: string) => ({
      programmeTitle,
      reports: programmes.filter((programmeReport: any) => programmeReport.programme.programme.name === programmeTitle)
    }))
  }
  return  programmes
}

export type SubmoduleReport = {
  id: number,
  programmeTitle: string
  programmeType: string
  moduleTitle?: string
  sectionTitle: string
  report: {
    id: number,
    url: string
  }
}

export type ProgrammeReort = {
  programmeTitle: string;
  submodules: {
    programmeType: string;
      reports: {
          programmeTitle: string;
          sectionTitle: string;
          url: string;
      }[];
  }[];
}

export const groupSubmoduleReports = (submoduleReports: SubmoduleReport[]) => {
  const programmeTitles = Array.from(
    new Set(submoduleReports.map(({ programmeTitle }: SubmoduleReport) => programmeTitle))
  ) as string[]

  const programmeTypes = Array.from(
    new Set(submoduleReports.map(({ programmeType }: SubmoduleReport) => programmeType))
  ) as string[]

  const groupedSubmodules = programmeTypes.map((programmeType: string) => ({
    programmeType,
    reports: submoduleReports
      .filter((submoduleReport: SubmoduleReport) => submoduleReport.programmeType === programmeType)
      .map(({ programmeTitle, moduleTitle, sectionTitle, report }: SubmoduleReport) => ({
        programmeTitle,
        sectionTitle: sectionTitle ?? moduleTitle,
        url: report.url
      }))
  }))

  const programmeReports = programmeTitles.map((programmeTitle: string) => {
    return {
      programmeTitle,
      submodules: groupedSubmodules.filter(
        groupedSubmodule => groupedSubmodule.reports.find(
          (report: { programmeTitle: string }) => report.programmeTitle === programmeTitle)
        )
    }
  }).map(programmeReport => {
    const { submodules,  programmeTitle } = programmeReport

    const filteredSubmodules = submodules.map(submodule => {
      const updatedReports = submodule.reports.filter(report => report.programmeTitle === programmeTitle)
      return {
        ...submodule,
        reports: updatedReports
      }
    })
    
    return {
      ...programmeReport,
      submodules: filteredSubmodules
    }
  })

  return programmeReports as ProgrammeReort[]
}

export const computeProgrammeProgress = (programme: any) => {
  const prorammeTypes = programme?.programmeTypes || []
  const programmeProgress = prorammeTypes.reduce((acc: number, programmeType: any) => {
    const { submodules } = programmeType

    return acc + submodules.reduce((acc: number, submodule: any) => acc + submoduleProgress(submodule.sections), 0)
  }, 0) / prorammeTypes.length

  if (programmeProgress / 100 === 1) {
    return programmeProgress
  }
  return parseFloat(Number(programmeProgress).toFixed(2))
}

export const computNextSectionSubmoduleSubissionIds = (
  programmeSubmodules: any,
  currentSubmoduleId?: string,
  currentSectionId?: string
) => {
  let currentSectionProps

  if (currentSubmoduleId && currentSectionId && programmeSubmodules) {
    const programmeSubmodule = programmeSubmodules.find((programmeSubmodule: any) => {
      if(programmeSubmodule[0]) {
        return programmeSubmodule[0].submoduleId === parseInt(currentSubmoduleId)
      }
      return programmeSubmodule[0]
    })
    if(programmeSubmodule) {
      currentSectionProps = programmeSubmodule[0].sections.find((section: any) => section.currentSectionId === parseInt(currentSectionId))
    }
  }
  return currentSectionProps
}

export const getNextSectionId = (
  programmeSubmodules: any,
  currentSubmoduleId?: string,
  currentSectionId?: string
) => {
  let currentNextSectionId
  if (programmeSubmodules && currentSubmoduleId && currentSectionId) {
    const nextSection = computNextSectionSubmoduleSubissionIds(programmeSubmodules, currentSubmoduleId, currentSectionId)
    if (nextSection?.nextSectionId) {
      const { nextSectionId } = nextSection
      currentNextSectionId = nextSectionId
    }
  }
  return currentNextSectionId
}

export const getNextSubmoduleSubmissionId = (
  programmeSubmodules: any,
  currentSubmoduleId?: string,
  currentSectionId?: string
) => {
  let currentNextSubModuleSubmissionId
  if (programmeSubmodules && currentSubmoduleId && currentSectionId) {
    const nextSubmoduleSubmission = computNextSectionSubmoduleSubissionIds(programmeSubmodules, currentSubmoduleId, currentSectionId)
    if (nextSubmoduleSubmission?.nextSubmoduleSubmissionId) {
      const { nextSubmoduleSubmissionId } = nextSubmoduleSubmission
      currentNextSubModuleSubmissionId = nextSubmoduleSubmissionId
    } 
  }
  return currentNextSubModuleSubmissionId
}

export const computeOrderedProgrammeTypes = (userProgramme: any) => {
  let orderedProgrammeTypesSubmodules
  if (userProgramme) {
    const userProgrammeTypes = orderBy(userProgramme?.programmeTypes || [], ['position'])
    orderedProgrammeTypesSubmodules = userProgrammeTypes.map((programmeType: any) => {
      const { submodules } = programmeType
      return submodules.map((submodule: any) => {
        const { sections } = submodule
        const orderedSections = orderBy(sections || [], ['position'], ['asc'])
        return {
          submoduleId: submodule.id,
          sections: orderedSections.map(orderedSection => {
            let currentSectionId
            let nextSectionId
            let nextSubmoduleSubmissionId
            const nextSectionIndex = orderedSections.findIndex((currentSection) => {
              let hasMatch
              if (currentSection?.id === orderedSection?.id) {
                currentSectionId = currentSection?.id
                hasMatch = currentSection?.id === orderedSection?.id
              }
              return hasMatch
            }) + 1
            if (nextSectionIndex < orderedSections.length) {
              nextSectionId = orderedSections[nextSectionIndex]?.id
              if (orderedSections[nextSectionIndex].subModuleSubmission?.id) {
                nextSubmoduleSubmissionId =  orderedSections[nextSectionIndex].subModuleSubmission?.id
              }
            }
            return { currentSectionId, nextSectionId, nextSubmoduleSubmissionId }
          })
        }
      })
    })
  }
  return orderedProgrammeTypesSubmodules
}