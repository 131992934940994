import * as React from 'react'
import { useAuthContext } from '../AuthProvider'
import { useUserProgramme, useUserProgrammes } from '../../hooks'
import { computeOrderedProgrammeTypes } from '../../utils'

type AppContextType = {
  drawerOpen: boolean
  toggleDrawer: () => void
  toggleChat: () => void
  toggleNoteDrawer: () => void
  notesDrawerOpen: boolean
  setArticlesFunction: () => void
  myWorkTitle: any
  setMyWorkTitle: (tab: any) => void
  programmeDetails: any
  setProgrammeDetails: (details: any) => void
  article: boolean
  unsetArticlesFunction: () => void
  toggleNotes: () => void
  toggleDocuments: () => void
  toggleReports: () => void
  mywork: string
  selectedProgramme: any
  setSelectedProgramme: (programme: any) => void
  programmeNote: any
  setProgrammeNote: (note: any) => void
  viewDocument: boolean
  setViewDocument: (document: boolean) => void
  noteName: any
  setNoteName: (noteName: any) => void
  setNameofNotee: (name: string) => void
  nameOfNote: string
  notiticationCount: number
  setNotiticationCount: (count: number) => void
  accountProgrammes: any
  accountProgramme?: any
  updateProgrammeId: (id: string) => void
  isLoadingProgramme: boolean
  programmeSubmodules: any
  updateUserProgramme: () => void
}

export const AppContext = React.createContext<AppContextType>({
  drawerOpen: false,
  toggleDrawer: () => null,
  toggleChat: () => null,
  toggleNoteDrawer: () => null,
  notesDrawerOpen: false,
  setArticlesFunction: () => null,
  myWorkTitle: null,
  setMyWorkTitle: () => null,
  programmeDetails: null,
  setProgrammeDetails: () => null,
  article: false,
  unsetArticlesFunction: () => null,
  toggleNotes: () => null,
  toggleDocuments: () => null,
  toggleReports: () => null,
  mywork: 'notes',
  selectedProgramme: null,
  setSelectedProgramme: () => null,
  programmeNote: null,
  setProgrammeNote: () => null,
  viewDocument: false,
  setViewDocument: () => null,
  noteName: null,
  setNoteName: () => null,
  setNameofNotee: () => null,
  nameOfNote: '',
  notiticationCount: 0,
  setNotiticationCount: () => null,
  accountProgrammes: [],
  accountProgramme: null,
  updateProgrammeId: (id?: string | number) => console.log(id),
  isLoadingProgramme: false,
  programmeSubmodules: [],
  updateUserProgramme: () => console.log()
})

export const useAppContext = () => React.useContext(AppContext)

const AppProvider: React.FC<{}> = ({ children }) => {
  const { user } = useAuthContext()
  const { userProgrammes } = useUserProgrammes(user?.id)
  const [prgrammeId, setProgrammeId] = React.useState('')
  const { userProgramme, isLoading: isLoadingProgramme, updateProgramme } = useUserProgramme(
    prgrammeId
  )
  const [nameOfNote, setNameOfNote] = React.useState('')
  const [drawerOpen, setDrawerOpen] = React.useState(true)
  const [notesDrawerOpen, setToggleNotesDrawer] = React.useState(true)
  const [chatOpen, setChatOpen] = React.useState(false)
  const [myWorkTitle, setMyWorkTitle] = React.useState(null)
  const [programmeDetails, setProgrammeDetails] = React.useState(null)
  const [article, setArticles] = React.useState(true)
  const [mywork, setMyWork] = React.useState('notes')
  const [selectedProgramme, setSelectedProgramme] = React.useState(null)
  const [programmeNote, setProgrammeNote] = React.useState(null)
  const [viewDocument, setViewDocument] = React.useState(false)
  const [noteName, setNoteName] = React.useState(null)
  const [notiticationCount, setNotiticationCount] = React.useState(0)

  const programmeSubmodules = React.useMemo(() => computeOrderedProgrammeTypes(userProgramme), [
    userProgramme
  ])

  function setArticlesFunction() {
    setArticles(true)
  }

  function unsetArticlesFunction() {
    setArticles(false)
  }

  function setNameofNotee(name: string) {
    setNameOfNote(name)
  }

  function toggleDrawer() {
    setDrawerOpen(!drawerOpen)
  }
  function toggleNotes() {
    setProgrammeNote(null)
    setMyWork('notes')
  }

  function toggleDocuments() {
    if (selectedProgramme) {
      setSelectedProgramme(null)
    }
    setMyWork('document')
  }

  function toggleReports() {
    if (viewDocument) {
      setViewDocument(false)
    }
    setMyWork('report')
  }

  function toggleNoteDrawer() {
    setToggleNotesDrawer(!notesDrawerOpen)
  }
  // Toggle Zendesk Chat/Help bubble
  function toggleChat() {
    setChatOpen(!chatOpen)
  }
  // @ts-ignore
  if (window.zE) {
    const status = chatOpen ? 'open' : 'close'
    const userName = user?.firstName + ' ' + user?.lastName
    // @ts-ignore
    window.zE('webWidget', status)
    // @ts-ignore
    window.zE('webWidget', status === 'open' ? 'show' : 'hide')

    // @ts-ignore
    window.zE('webWidget', 'identify', {
      name: userName,
      email: user?.email
    })
    // @ts-ignore
    window.zE('webWidget', 'updateSettings', {
      contactForm: {
        fields: [
          { id: 'name', prefill: { '*': userName } },
          { id: 'email', prefill: { '*': user?.email } }
        ]
      }
    })
    // @ts-ignore
    window.zE('webWidget:on', 'close', () => {
      setChatOpen(false)
    })
  }

  return (
    <AppContext.Provider
      value={{
        drawerOpen,
        toggleDrawer,
        toggleChat,
        notesDrawerOpen,
        toggleNoteDrawer,
        myWorkTitle,
        setArticlesFunction,
        setMyWorkTitle,
        programmeDetails,
        setProgrammeDetails,
        article,
        unsetArticlesFunction,
        toggleDocuments,
        toggleNotes,
        toggleReports,
        mywork,
        selectedProgramme,
        setSelectedProgramme,
        programmeNote,
        setProgrammeNote,
        viewDocument,
        setViewDocument,
        noteName,
        setNoteName,
        setNameofNotee,
        nameOfNote,
        notiticationCount,
        setNotiticationCount,
        accountProgrammes: {
          userProgrammes
        },
        accountProgramme: userProgramme,
        updateProgrammeId: (newId: string) => setProgrammeId(newId),
        isLoadingProgramme,
        programmeSubmodules,
        updateUserProgramme: () => updateProgramme()
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppProvider
