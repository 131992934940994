import { theme as chakraTheme } from '@chakra-ui/core'
import images from './images'

const theme = {
  ...chakraTheme,
  fonts: {
    body: 'Poppins, sans-serif',
    mono: 'Poppins, sans-serif',
    heading: 'Poppins, sans-serif'
  },
  colors: {
    ...chakraTheme.colors,
    brand: {
      50: '#f2f2f8',
      100: '#d7d7db',
      200: '#bcbcc1',
      300: '#a1a1a7',
      400: '#86868e',
      500: '#115264',
      600: '#54545b',
      700: '#3c3c41',
      800: '#242428',
      900: '#0c0c12'
    },
    asera: {
      500: '#115264',
      400: '#F4A807'
    },
    accent: {
      50: '#fff7db',
      100: '#ffe5ae',
      200: '#fdd57f',
      300: '#fbc44e',
      400: '#fab31f',
      500: '#FAB423',
      600: '#af7700',
      700: '#7e5500',
      800: '#4c3300',
      900: '#1d1000'
    },
    success: {
      50: '#e3fbee',
      100: '#c3ebd7',
      200: '#a0dcbf',
      300: '#7ccda7',
      400: '#59bf8e',
      500: '#40a674',
      600: '#30815a',
      700: '#205c40',
      800: '#0e3825',
      900: '#001509'
    },
    oddoRed: {
      500: '#c53030'
    },
    lucaniaBlue: {
      500: '#115264'
    },
    lucaniaOrange: {
      500: '#F4A807'
    },
    lucaniaGray: {
      100: '#98989880'
    }
  },
  boxShadow: '0px 0px 4px 4px rgba(0,0,0,0.4)',
  gridGutter: 1 // rem - taken from Chakra UI space scale https://chakra-ui.com/theme#spacing
}

export { theme, images }
