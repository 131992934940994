import { Flex, FlexProps, Button, Text } from '@chakra-ui/core'
import { useField } from 'formik'
import { Variants } from 'framer-motion'
import * as React from 'react'
import { FilePlus } from 'react-feather'
import { FillLoader, MotionFlex } from '../../'
import { ERROR_TOAST, SUCCESS_TOAST } from '../../../constants'
import { EMPTY_FILE } from '../../../constants/index'
import { UploadFile } from '../../../generated/graphql'
import { DisplayLanguage } from '../../../utils/languageHelpers'
import strapiHelpers from '../../../utils/strapiHelpers'
import { AddFileButton, AddFileWrapper, HiddenInput, Wrapper } from './styles'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

type ImageUploaderProps = FlexProps & {
  name: string
  onUploadComplete?: (file: UploadFile) => void
  updateUserDetails?: (variables: any) => void
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
  name,
  onUploadComplete,
  updateUserDetails,
  ...rest
}) => {
  const [stateFile, setStateFile] = React.useState<File | undefined>()
  const [uploading, setUploading] = React.useState(false)
  const [field, meta, helpers] = useField<UploadFile>(name)

  const inputRef = React.createRef<HTMLInputElement>()

  React.useEffect(() => {
    const handleUpload = async (file: File) => {
      setUploading(true)
      try {
        const strapiFile = await strapiHelpers.upload(file)
        toast.success('Image has been uploaded.', SUCCESS_TOAST)
        helpers.setValue(strapiFile.data[0])
        if (onUploadComplete) {
          onUploadComplete(strapiFile.data[0])
        }
        setUploading(false)
      } catch (e) {
        setUploading(false)
        toast.error('Something went wrong while uploading your photo.', ERROR_TOAST)
      }
    }
    if (stateFile) {
      handleUpload(stateFile)
    }
    // eslint-disable-next-line
  }, [stateFile])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files) {
      setStateFile(files[0])
    }
  }

  const variants: Variants = {
    in: {
      opacity: 1
    },
    initial: {
      opacity: 0
    },
    hover: {
      backgroundColor: 'rgba(0,0,0,0.05)',
      cursor: 'pointer'
    }
  }

  const localUrl = stateFile ? window.URL.createObjectURL(stateFile) : ''

  const buttonsVariant: Variants = {
    initial: {
      y: 60,
      opacity: 0,
      pointerEvents: 'none'
    },
    hover: {
      y: stateFile || !!field.value?.id ? 0 : 60,
      opacity: 1,
      pointerEvents: 'auto',
      transition: {
        staggerChildren: 0.2
      }
    }
  }

  const handleRefresh = () => {
    if (inputRef?.current) {
      inputRef.current.click()
    }
  }

  return (
    <Wrapper {...rest}>
      <AddFileWrapper
        animate="in"
        rounded="md"
        initial="initial"
        whileHover="hover"
        variants={variants}
        backgroundImage={`url(${field.value ? field.value.url : localUrl})`}
      >
        <AddFileButton
          rounded="md"
          h="148px"
          w="148px"
          align="center"
          htmlFor={name}
          justify="center"
          borderWidth={1}
        >
          {!stateFile && !!!field.value?.id && (
            <Flex justify="center" align="center">
              <FilePlus size={20} />
              <Text ml={3}>
                {DisplayLanguage('personalScreen', 'changePhoto') || 'Add Picture'}
              </Text>
            </Flex>
          )}
          {uploading && <FillLoader bg="rgba(0,0,0,0.2)" />}
        </AddFileButton>
      </AddFileWrapper>
      <MotionFlex flexDir="column" p={4} variants={buttonsVariant} alignItems="center">
        <Button
          w="143px"
          rounded="md"
          mb="12px"
          onClick={handleRefresh}
          variant="outline"
          color="brand"
        >
          Upload Image
        </Button>
        <Button
          w="143px"
          rounded="md"
          onClick={() => (field.value ? helpers.setValue(EMPTY_FILE) : setStateFile(undefined))}
          color="brand"
          variant="ghost"
        >
          Remove Image
        </Button>
      </MotionFlex>
      <MotionFlex justifyContent="flex-end" mb="4px" mr="16px">
        <Button
          variant="solid"
          color="#fff"
          bg="#115264"
          onClick={async () => {
            if (updateUserDetails) {
              await updateUserDetails({
                variables: {
                  input: {
                    profilePicture: field.value?.id
                  }
                }
              })
            }
          }}
        >
          Update
        </Button>
      </MotionFlex>
      <HiddenInput ref={inputRef} onChange={onChange} type="file" multiple name={name} id={name} />
      {meta.touched && meta.error ? (
        <Text color="red.500" textAlign="right">
          {meta.error}
        </Text>
      ) : null}
    </Wrapper>
  )
}

export default React.memo(ImageUploader)
