import { lazy } from 'react'
import { RouteProps } from 'react-router'

export interface PrivateRouteObject extends RouteProps {
  exact: boolean
  path: string
  breadcrumb: any
  component: any
  title: string
}

const Programmes = lazy(() => import('../containers/Programmes'))
const Programme = lazy(() => import('../containers/Programme'))
const MyProgramme = lazy(() => import('../containers/MyProgramme'))
const PasswordChange = lazy(() => import('../containers/Onboarding'))
const MyProgrammeDetail = lazy(() => import('../containers/MyProgrammeDetail'))
const MyProgrammeSubModelDetail = lazy(() => import('../containers/MyProgrammeSubModelDetail'))
const MyLibrary = lazy(() => import('../containers/MyLibrary'))
const MyWork = lazy(() => import('../containers/MyWork'))
const Section = lazy(() => import('../containers/Section'))
const SubSection = lazy(() => import('../containers/SubSection/'))
const BookAppointment = lazy(() => import('../containers/SubSection/BookAppointment'))
const ProgrammeTypes = lazy(() => import('../containers/ProgrammeTypes'))
const Profile = lazy(() => import('../containers/Profile'))
const PersonalDetails = lazy(() => import('../containers/Profile/personalDetails'))
const LanguageSettings = lazy(() => import('../containers/Profile/languageSettings'))
const Login = lazy(() => import('../containers/Login'))
const TcsAndCs = lazy(() => import('../containers/TcsAndCs'))
const ConfirmEmail = lazy(() => import('../containers/ConfirmEmail'))
const ForgotPassword = lazy(() => import('../containers/ForgotPassword'))
const ResetPassword = lazy(() => import('../containers/ResetPassword'))
const Landing = lazy(() => import('../containers/Landing'))
const Competency = lazy(() => import('../containers/Competency'))
const IndexOnboarding = lazy(() => import('../containers/Onboarding/getStarted'))
const LanguagesOnboarding = lazy(() => import('../containers/Onboarding/languages'))
const WelcomeOnboarding = lazy(() => import('../containers/Onboarding/welcome'))
const DetailsOnboarding = lazy(() => import('../containers/Onboarding/details'))
const ExpectOnboarding = lazy(() => import('../containers/Onboarding/expect'))
const UpdateDetails = lazy(() => import('../containers/Onboarding/update'))
const HelpAndSupport = lazy(() => import('../containers/HelpAndSupport'))
const Notifications = lazy(() => import('../containers/Notifications'))
const LoginRedirect = lazy(() => import('../containers/SSOLogin/LoginRedirect'))
const LoginWithoutSSO = lazy(() => import('../containers/LoginWithoutSSO'))
// const ConstructionPage = lazy(() => import('../containers/Landing/ConstructionPage'))
const MySubModules = lazy(() => import('../containers/MySubModules'))
const ProgrammeSubmissions = lazy(() => import('../containers/ProgrammeSubmissions'))
const AboutUs = lazy(() => import('../containers/AboutUs'))
const LoginWithSSO = lazy(() => import('../containers/LoginWithSSO'))
const Dashboard = lazy(() => import('../containers/Dashboard'))
const DownloadApp = lazy(() => import('../containers/DownloadApp'))
const TermsofService = lazy(() => import('../containers/TermsofService'))
const PrivacyPolicy = lazy(() => import('../containers/PrivacyPolicy'))
const FAQs = lazy(() => import('../containers/FAQs'))
const SectionComplete = lazy(() => import('../containers/SectionComplete'))
const ModuleComplete = lazy(() => import('../containers/ModuleComplete'))

const PRIVATE_ROUTES: PrivateRouteObject[] = [
  {
    exact: true,
    path: '/auth/dashboard',
    breadcrumb: 'Dashboard',
    component: Dashboard,
    title: 'Dashboard'
  },
  {
    exact: true,
    path: '/auth/programmes',
    breadcrumb: 'Programmes',
    component: Programmes,
    title: 'Programmes'
  },
  {
    exact: true,
    path: '/auth/programmes/:programmeId',
    breadcrumb: 'Programme',
    component: Programme,
    title: 'Programme'
  },
  {
    exact: true,
    path: '/auth/programmes/:programmeId/submodule/:subModuleId/section/:sectionId/submoduleSubmission/:subModuleSubmissionId',
    breadcrumb: 'Module Submission',
    component: SubSection,
    title: 'Section'
  },
  {
    exact: true,
    path: '/auth/programmes/:programmeId/submodule/:subModuleId/section/:sectionId/submoduleSubmission/:subModuleSubmissionId/complete/nextSection/:nextSectionId',
    breadcrumb: 'Module Submission',
    component: SubSection,
    title: 'Section'
  },
  {
    exact: true,
    path: '/auth/programmes/:programmeId/submodule/:subModuleId/section/:sectionId/submoduleSubmission/:subModuleSubmissionId/completed',
    breadcrumb: 'Module Submission',
    component: SectionComplete,
    title: 'Section'
  },
  {
    exact: true,
    path:
      '/auth/programmes/:programmeId/subModule/:subModuleId/section/:sectionId/bookappointment/:subModuleSubmissionId/:bookingStep/:classId/:nextSectionId',
    breadcrumb: 'Book appointment',
    component: BookAppointment,
    title: 'Book Appointment'
  },
  {
    exact: true,
    path:
      '/auth/programmes/:programmeId/subModule/:subModuleId/section/:sectionId/bookappointment/:subModuleSubmissionId/:bookingStep/:classId',
    breadcrumb: 'Book appointment',
    component: BookAppointment,
    title: 'Book Appointment'
  },
  {
    exact: true,
    path: '/auth/programmes/:programmeId/section/:sectionId',
    breadcrumb: 'Module Complete',
    component: ModuleComplete,
    title: 'Module Complete'
  },
  {
    exact: true,
    path: '/auth/helpandsupport/aboutus',
    breadcrumb: 'Introduction',
    component: AboutUs,
    title: 'Introduction'
  },
  {
    exact: true,
    path: '/auth/myprogramme/:moduleId',
    breadcrumb: 'My Programme',
    component: MyProgrammeDetail,
    title: 'Programme Detail'
  },
  {
    exact: true,
    path: '/auth/myprogramme/my/:moduleId',
    breadcrumb: 'My Sud Module Programme',
    component: MyProgrammeSubModelDetail,
    title: 'Sud Module Programme Detail'
  },
  {
    exact: true,
    path: '/auth/myprogramme',
    breadcrumb: 'My Programme',
    component: MyProgramme,
    title: 'My Programme'
  },
  {
    exact: true,
    path: '/auth/mywork',
    breadcrumb: 'My Work',
    component: MyWork,
    title: 'My Work'
  },
  {
    exact: true,
    path: '/auth/mywork/:moduleId',
    breadcrumb: 'My Work',
    component: MyProgrammeDetail,
    title: 'My Work Detail'
  },
  {
    exact: true,
    path: '/auth/mywork/:moduleId/section/:sectionId',
    breadcrumb: 'My Work Section',
    component: Section,
    title: 'My Work Section'
  },
  {
    exact: true,
    path: '/auth/mylibrary',
    breadcrumb: 'My Library',
    component: MyLibrary,
    title: 'My Library'
  },

  {
    exact: true,
    path: '/auth/myprofile',
    breadcrumb: 'My Profile',
    component: Profile,
    title: 'My Profile'
  },
  {
    exact: true,
    path: '/auth/helpandsupport',
    breadcrumb: 'Help Center',
    component: HelpAndSupport,
    title: 'Help Center'
  },
  {
    exact: true,
    path: '/auth/notifications',
    breadcrumb: 'Notifications',
    component: Notifications,
    title: 'Notifications'
  },
  {
    exact: true,
    path: '/auth/myprofile/personaldetails',
    breadcrumb: 'Personal Details',
    component: PersonalDetails,
    title: 'Personal Details'
  },
  {
    exact: true,
    path: '/auth/myprofile/languagesettings',
    breadcrumb: 'Language Settings',
    component: LanguageSettings,
    title: 'Language Settings'
  },
  {
    exact: true,
    path: '/auth/mysubmodules',
    breadcrumb: 'My Sub Modules',
    component: MySubModules,
    title: 'My Sub Modules'
  },
  {
    exact: true,
    path: '/auth/myprogramme/:moduleId/section/:sectionId/:progSubId',
    breadcrumb: 'My Section',
    component: Section,
    title: 'Section'
  },
  {
    exact: true,
    path: '/auth/myprogramme/:moduleId/subsection/:sectionId/:progSubId',
    breadcrumb: 'My Sud Module Section',
    component: SubSection,
    title: 'Sud Module Section'
  },
  {
    exact: true,
    path: '/auth/myprogramme/:moduleId/subsectioncompetency/:sectionId/:progSubId',
    breadcrumb: 'My Sud Module Section',
    component: Competency,
    title: 'Endpoint'
  },
  {
    exact: true,
    path: '/auth/myprogramme/types/:programmeSubmissionId/questions',
    breadcrumb: 'My Programme Questions',
    component: Section,
    title: 'My Programme Questions'
  },
  {
    exact: true,
    path: '/auth/myprogramme/notes/:programmeSubmissionId',
    breadcrumb: 'My Programme Notes',
    component: Section,
    title: 'My Programme Notes'
  },
  {
    exact: true,
    path: '/auth/myprogramme/types/:programmeSubmissionId',
    breadcrumb: 'My Programme Submission',
    component: ProgrammeTypes,
    title: 'My Programme Submission'
  },
  {
    exact: true,
    path: '/auth/profile',
    breadcrumb: 'My Profile',
    component: Profile,
    title: 'My Profile'
  },
  {
    exact: true,
    path: '/auth/Onboarding/getstarted',
    breadcrumb: 'Onboarding',
    component: IndexOnboarding,
    title: 'Onboarding'
  },
  {
    exact: true,
    path: '/auth/onboarding/languages',
    breadcrumb: 'Languages',
    component: LanguagesOnboarding,
    title: 'Languages'
  },
  {
    exact: true,
    path: '/auth/onboarding/welcome',
    breadcrumb: 'Welcome',
    component: WelcomeOnboarding,
    title: 'Welcome'
  },
  {
    exact: true,
    path: '/auth/onboarding/changepassword',
    breadcrumb: 'ChangePassword',
    component: PasswordChange,
    title: 'Welcome'
  },
  {
    exact: true,
    path: '/auth/onboarding/details',
    breadcrumb: 'Details',
    component: DetailsOnboarding,
    title: 'Your Details'
  },
  {
    exact: true,
    path: '/auth/onboarding/expect',
    breadcrumb: 'Expect',
    component: ExpectOnboarding,
    title: 'What To Expect'
  },
  {
    exact: true,
    path: '/auth/onboarding/update',
    breadcrumb: 'Update Details',
    component: UpdateDetails,
    title: 'Update Details'
  },
  {
    exact: true,
    path: '/auth/programme-submissions',
    breadcrumb: 'My Programme Submissions',
    component: ProgrammeSubmissions,
    title: 'My Programme Submissions'
  },
  {
    exact: true,
    path: '/auth/helpandsupport/terms-of-service',
    breadcrumb: 'Terms of Service',
    component: TermsofService,
    title: 'Terms of Service'
  },
  {
    exact: true,
    path: '/auth/helpandsupport/privacy-policy',
    breadcrumb: 'Privacy Policy',
    component: PrivacyPolicy,
    title: 'Privacy Policy'
  },
  {
    exact: true,
    path: '/auth/helpandsupport/faqs',
    breadcrumb: 'FAQs',
    component: FAQs,
    title: 'FAQs'
  }
]

const PUBLIC_ROUTES = [
  {
    exact: true,
    title: 'Landing Page',
    path: '/landing',
    component: Landing
  },
  {
    exact: true,
    title: 'Login',
    path: '/',
    component: Login
  },
  {
    exact: true,
    title: 'Login',
    path: '/loginwithoutsso',
    component: LoginWithoutSSO
  },
  {
    exact: true,
    title: 'Terms & Conditions',
    path: '/terms-conditions',
    component: TcsAndCs
  },
  {
    exact: true,
    title: 'Confirm Email',
    path: '/confirm-email',
    component: ConfirmEmail
  },
  {
    exact: true,
    title: 'Forgot Password',
    path: '/forgot-password',
    component: ForgotPassword
  },
  {
    exact: true,
    title: 'Reset Password',
    path: '/reset-password',
    component: ResetPassword
  },
  {
    exact: true,
    path: '/auth/:providerName/redirect',
    breadcrumb: 'redirect',
    component: LoginRedirect,
    title: 'redirect'
  },
  {
    exact: true,
    title: 'Login',
    path: '/loginwithsso',
    component: LoginWithSSO
  },
  {
    exact: true,
    title: 'Download App',
    path: '/download-app',
    component: DownloadApp
  }
]

export { PUBLIC_ROUTES, PRIVATE_ROUTES }
