import { Checkbox, CheckboxProps } from '@chakra-ui/core'
import { useField } from 'formik'
import * as React from 'react'
import { useMediaQuery } from 'react-responsive'

import { Text } from '../../../typography'
import { LabelProps } from '../styles'

export type ConnectedCheckboxProps = LabelProps &
  CheckboxProps & {
    label?: string
    name: string
  }

const ConnectedCheckbox: React.FC<ConnectedCheckboxProps> = ({ label, ...rest }) => {
  const [field, meta] = useField(rest.name)
  const isMobile = useMediaQuery({ query: '(max-width: 30em)' })
  return (
    <>
      <Checkbox {...field} id={field.name} {...rest} variantColor="asera">
        <Text fontSize={isMobile ? '10px' : '1rem'} textAlign="right">
          {label}
        </Text>
      </Checkbox>
      {meta.touched && meta.error ? (
        <Text color="red.500" textAlign="right">
          {meta.error}
        </Text>
      ) : null}
    </>
  )
}

export default ConnectedCheckbox

ConnectedCheckbox.defaultProps = {
  mb: 2,
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginLeft: '0px'
}
