import useSWR from 'swr'
import { requestGet } from '../utils/services'

const useSection = (sectionId?: string) => {
  const sectionKey = sectionId ? `/getSectionLinks/${sectionId}` : null
  const { data: section, error } = useSWR(sectionKey, (url: string) => requestGet(url), {
    revalidateOnFocus: false
  })

  if (section) {
    return {
      section: section,
      isLoading: !section
    }
  }
  return {
    section: { id: '', events: [] },
    isLoading: !section && !error
  }
}

export default useSection
