import { Flex } from '@chakra-ui/core'
import React, { FC } from 'react'
import { ArrowLeft } from 'react-feather'
import { useHistory } from 'react-router-dom'
import { H5 } from '../../typography'
import { DisplayLanguage, TranslateTitle } from '../../utils/languageHelpers'

type Props = {
  screenName?: string
  model?: Object
  selector: string
  canGoBack?: boolean
  onClickBack?: () => void
}
/**
 *  TODO: add comments
 */
const CustomHeader: FC<Props> = ({ screenName, model, selector, canGoBack, onClickBack }) => {
  const { goBack } = useHistory()

  return (
    <Flex
      p={4}
      top={0}
      left={0}
      bg="white"
      width="100%"
      height="64px"
      zIndex={1300}
      position="fixed"
      data-testid="custom-header"
      borderBottomWidth={1}
    >
      {canGoBack && (
        <ArrowLeft
          data-testid="back-button"
          size={28}
          onClick={() => (!!onClickBack ? onClickBack() : goBack())}
        />
      )}
      <H5
        ml={4}
        fontWeight="bold"
        color="oddoRed.500"
        data-testid="header-text"
        style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
      >
        {screenName && DisplayLanguage(screenName, selector)}
        {model && TranslateTitle(model, selector)}
      </H5>
    </Flex>
  )
}

export default CustomHeader
