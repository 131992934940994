import * as React from 'react'
import { Text, Box, Button } from '@chakra-ui/core'
import MotionFlex from '../MotionFlex'
import { Maybe } from './../../generated/graphql'

export type MyParticipant = {
  name?: Maybe<string> | undefined
  email?: Maybe<string> | undefined
}

export type ParticipantProps = {
  participant?: MyParticipant
  onClick?: (participant: any) => void
  hasButton?: boolean
}

const Participant: React.FC<ParticipantProps> = ({ participant, onClick, hasButton }) => {
  if (hasButton) {
    return (
      <MotionFlex
        mt="10px"
        flexDir="row"
        justifyContent="space-between"
        alignItems="center"
        h="48px"
        bg="rgba(152, 152, 152, 0.1)"
        borderRadius="8px"
        px="10px"
      >
        <Text fontSize="16px" my="13px" fontWeight={400}>
          {participant?.email}
        </Text>
        {participant && onClick && (
          <Button
            variant="ghost"
            variantColor="brand"
            onClick={() => onClick(participant)}
            fontSize="16px"
            fontWeight={500}
          >
            Resend
          </Button>
        )}
      </MotionFlex>
    )
  } else {
    return (
      <MotionFlex
        mt="10px"
        flexDir="row"
        justifyContent="space-between"
        alignItems="center"
        h="48px"
        bg="rgba(152, 152, 152, 0.1)"
        borderRadius="8px"
        px="10px"
      >
        <Text fontSize="16px" my="13px" fontWeight={400}>
          {participant?.email}
        </Text>
        <Box
          h="24px"
          px="12px"
          py="2px"
          bg="#16BF97"
          color="#fff"
          borderRadius="4px"
          fontSize="14px"
          fontWeight={400}
          textAlign={'center'}
          ml={5}
        >
          Complete
        </Box>
      </MotionFlex>
    )
  }
}

export default Participant
