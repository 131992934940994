import { Divider, Flex, Box, Image } from '@chakra-ui/core'
import { useAnimation, Variants } from 'framer-motion'
import * as React from 'react'
import { useMediaQuery } from 'react-responsive'
import { ColorProps } from 'styled-system'
import { useAppContext } from '../../context/AppProvider'
import { NavItem } from '../../navigation'
import Header from '../Header'
import SideBarItem from './SideBarItem'
import { MenuCont, Overlay, RenderWrapper } from './styles'
import { LogOut } from 'react-feather'
import { useAuthContext } from '../../context/AuthProvider'
import { useHistory, useLocation } from 'react-router-dom'
import { images } from '../../theme'
import SideBarButton from './SideBarButton'
import { DisplayLanguage } from '../../utils/languageHelpers'

type SideBarProps = ColorProps & {
  borderColor?: string
  closeOnNavigate?: boolean
  color: string
  hoverColor: string
  navItems: NavItem[]
  tooltipBg?: string
  tooltipColor?: string
}

const SideBar: React.FC<SideBarProps> = ({
  bg,
  borderColor,
  children,
  color,
  hoverColor,
  navItems,
  tooltipBg,
  tooltipColor,
  closeOnNavigate
}) => {
  const { drawerOpen, toggleDrawer } = useAppContext()
  const { logout } = useAuthContext()
  const history = useHistory()
  const { pathname } = useLocation()

  const handleLogout = () => {
    logout && logout()
    history.push('/')
  }

  const controls = useAnimation()

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 40em)' })

  const isOnboarding = window.location.pathname.includes('onboarding')

  React.useEffect(() => {
    if (drawerOpen) {
      controls.start('open')
    } else {
      controls.start('closed')
    }
  }, [isTabletOrMobile, drawerOpen, controls])

  const variants: Variants = {
    open: {
      x: 0,
      width: 205,
      transition: { staggerChildren: 0.05, delayChildren: 0.05, stiffness: 10, damping: 5 }
    },
    closed: {
      x: isTabletOrMobile ? -205 : 0,
      width: isTabletOrMobile ? 205 : 64,
      transition: {
        stiffness: 80,
        staggerDirection: -1,
        staggerChildren: 0.1
      }
    }
  }

  return !isOnboarding ? (
    <>
      <MenuCont
        bg={bg}
        flexDir="column"
        animate={controls}
        variants={variants}
        alignItems="flex-start"
        // Calculate offset based on icon size
        iconOffset={(64 - 20) / 2}
        justifyContent="flex-start"
        initial={{ width: drawerOpen ? 205 : 64 }}
        boxShadow="0px 1px 4px rgba(196, 196, 196, 0.5);"
      >
        <Box width="100%">
          <Flex
            width="100%"
            height="73px"
            alignItems="center"
            flexDirection="row"
            borderBottomWidth={0}
            justifyContent="space-between"
            borderColor={borderColor}
            position="relative"
          >
            <Image
              src={images.LucaniaLogoRed}
              ml="20px"
              my="9px"
              w={142}
              h={47}
              alignSelf="center"
              pr={5}
            />
            {isTabletOrMobile ? (
              <SideBarButton color="grey" open={drawerOpen} onClick={toggleDrawer} />
            ) : null}
          </Flex>
        </Box>
        <Box width="100%">
          {!isOnboarding &&
            navItems
              .filter((nav) => nav.main)
              .map((props) => {
                return (
                  <SideBarItem
                    color={color}
                    key={props.title}
                    hoverColor={hoverColor}
                    tooltipColor={tooltipColor}
                    tooltipBg={tooltipBg}
                    closeOnNavigate={closeOnNavigate}
                    {...props}
                  />
                )
              })}
        </Box>
        <Box width="100%">
          <Divider m="20px" borderColor="#989898" border="1px" opacity={0.25} />
        </Box>
        <Box width="100%">
          {!isOnboarding &&
            navItems
              .filter((nav) => !nav.main)
              .map((props) => {
                return (
                  <SideBarItem
                    color={color}
                    key={props.title}
                    hoverColor={hoverColor}
                    tooltipColor={tooltipColor}
                    tooltipBg={tooltipBg}
                    closeOnNavigate={closeOnNavigate}
                    {...props}
                    isNotification={props.to === '/auth/notifications' ? true : false}
                  />
                )
              })}
        </Box>
        <Box
          width="100%"
          position="absolute"
          bottom="40px"
          data-testid="dashboard"
          onClick={handleLogout}
        >
          <SideBarItem
            color={color}
            key="logout"
            hoverColor={hoverColor}
            tooltipColor={tooltipColor}
            tooltipBg={tooltipBg}
            closeOnNavigate={closeOnNavigate}
            title={DisplayLanguage('dashboardScreen', 'logOutDashboard') || 'Logout'}
            icon={<LogOut />}
            to="/logout"
          />
        </Box>
      </MenuCont>
      <RenderWrapper
        className="render-wrapper"
        pl={isTabletOrMobile ? 0 : drawerOpen ? '205px' : !isOnboarding ? '64px' : 0}
      >
        <Flex flexDirection="row" w="100%">
          <Box zIndex={0} w="100%">
            <Header isDashboard={pathname === '/auth/dashboard' ? true : false} />
            {children}
            {isTabletOrMobile && (
              <Overlay
                onClick={toggleDrawer}
                initial={{ opacity: 0 }}
                pointerEvents={drawerOpen ? 'auto' : 'none'}
                animate={drawerOpen ? { opacity: 1 } : { opacity: 0 }}
              />
            )}
          </Box>
        </Flex>
      </RenderWrapper>
    </>
  ) : (
    <>
      <Header isDashboard={pathname === '/auth/dashboard' ? true : false} />
      {children}
    </>
  )
}

export default SideBar

SideBar.defaultProps = {
  color: 'white',
  bg: 'gray.900',
  hoverColor: 'gray.800',
  borderColor: 'gray.200'
}
