import React from "react"
import MotionFlex from "../MotionFlex"
import { orderBy } from "lodash"
import ProgrammeItem from "../ProgrammeItem"
import { computeProgrammeProgress } from "../../utils"
import { Skeleton, Stack } from "@chakra-ui/core"
import { useAuthContext } from "../../context/AuthProvider"

type UserProgrammesProps = {
  userProgrammes: any
}

type ProgrammeType = {
  submodules: {
    id: number
  }[]
}

const UserProgrammes: React.FC<UserProgrammesProps> = ({ userProgrammes }) => {
  const { user: authedUser } = useAuthContext()
  return userProgrammes ? (
    <MotionFlex flexDir={{ xs: 'column', sm: 'row' }} my="16px" flexWrap="wrap">
      {(() => {
        return (orderBy(userProgrammes || [], ['position'], ['asc']))
        .map((userProgramme: any) => {
          const {
            id,
            start,
            name,
            programmeTypes
          } = userProgramme
          const programmeModules = programmeTypes
            .filter((programmeType: ProgrammeType) => programmeType.submodules)
            .map((programmeType: ProgrammeType) => programmeType.submodules)
            .flat()
          let three60formId
          let userId
          let submodsubid
          let secid
          let feedfoward_url_id
          programmeModules.forEach((programmeModule: any) => {
            programmeModule.sub_module_submissions.forEach((sub_module_submission: any) => {
              const { user, three_60_survey_form_id, feedfoward_url } = sub_module_submission
              if(three_60_survey_form_id && (authedUser && authedUser.id === user)) {
                const submissionSection = sub_module_submission.section
                const { section } = submissionSection[0]
                three60formId = three_60_survey_form_id
                userId = user
                secid = section.id
                submodsubid = sub_module_submission.id
              }
              if (feedfoward_url && (authedUser && authedUser.id === user)) {
                feedfoward_url_id = feedfoward_url
              }
            })
          })
          
          return (
            <Skeleton
              isLoaded={name.length > 0}
              fadeInDuration={2}
            >
              <ProgrammeItem
                key={id}
                programme={{
                  id,
                  name,
                  submodules: programmeModules.length,
                  progress: computeProgrammeProgress(userProgramme),
                  start,
                  three60formId,
                  userId,
                  section: secid,
                  submodsubmission: submodsubid,
                  feedfoward_url_id: feedfoward_url_id
                }}
              />
            </Skeleton>
          )
        })
      })()}
    </MotionFlex>
  ) : (
    <Stack w='100%' flexDir='row'>
      {[0, 1, 2].map((index: number) => (
        <Skeleton
          key={index}
          fadeInDuration={2}
          w={{
            xs: '100%',
            sm: '330px'
          }}
          h="148px"
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.2)"
          borderRadius="10px"
          px="16px"
          mr={{
            xs: 0,
            sm: '15px'
          }}
        />
      ))}
    </Stack>
  )
}
export default UserProgrammes