import { Flex } from '@chakra-ui/core'
import React, { Suspense } from 'react'
import { HelpCircle } from 'react-feather'
import {
  MdDashboard,
  MdLibraryBooks,
  MdChromeReaderMode,
  MdTextSnippet,
  MdAccountCircle,
  MdNotifications
} from 'react-icons/md'
import { Redirect, Route, RouteProps, Switch } from 'react-router'
import { BrowserRouter as Router } from 'react-router-dom'
import { FillLoader, MotionFlex } from '../components'
import OnboardingTour from '../components/OnboardingTour'
import SideBar from '../components/SideBar'
import PageNotFound from '../containers/PageNotFound'
import { useAuthContext } from '../context/AuthProvider'
import { useNotesContext } from '../context/NotesProvider'
import { DisplayLanguage } from '../utils/languageHelpers'
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from './routes'
import { useMediaQuery } from 'react-responsive'
import NotesSideBar from '../components/NotesSideBar'

interface RouteType extends RouteProps {
  component: any
}

export type NavItem = {
  to: string
  title: string
  icon: React.ReactNode
  subMenu?: Array<{ to: string; title: string }>
  main: boolean
}

const PrivateRoute = ({ component: Component, ...rest }: RouteType) => {
  const { isAuthenticating, isAuthenticated } = useAuthContext()

  if (isAuthenticating) {
    return <FillLoader />
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Suspense fallback={<FillLoader color="black" />}>
            <Component {...rest} />
          </Suspense>
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  )
}

const PublicRoute = ({ component: Component, ...rest }: RouteType) => (
  <Route {...rest}>
    <Component />
  </Route>
)

const Navigation = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 40em)' })
  const { openNotes } = useNotesContext()

  const NAV_ITEMS: NavItem[] = [
    {
      to: `/auth/dashboard`,
      title: DisplayLanguage('menu', 'home') || 'Dashboard',
      icon: <MdDashboard size={18} className="sidebar-menu-icon icon-menu" />,
      main: true
    },
    {
      to: `/auth/programmes`,
      title: DisplayLanguage('menu', 'myProgramme') || 'Programmes',
      icon: <MdLibraryBooks size={18} className="sidebar-menu-icon icon-menu" />,
      main: true
    },
    {
      to: `/auth/mylibrary`,
      title: DisplayLanguage('menu', 'myLibrary') || 'My Library',
      icon: <MdChromeReaderMode size={18} className="sidebar-menu-icon" />,
      main: true
    },
    {
      to: `/auth/mywork`,
      title: DisplayLanguage('menu', 'myWork') || 'My Work',
      icon: <MdTextSnippet size={18} className="sidebar-menu-icon myWork" />,
      main: true
    },
    {
      to: `/auth/myprofile`,
      title: DisplayLanguage('menu', 'myProfile') || 'My Profile',
      icon: <MdAccountCircle size={18} className="sidebar-menu-icon" />,
      main: true
    },
    {
      to: `/auth/notifications`,
      title: DisplayLanguage('menu', 'notifications') || 'Notifications',
      icon: <MdNotifications size={18} className="sidebar-menu-icon" />,
      main: false
    },
    {
      to: `/auth/helpandsupport`,
      title: DisplayLanguage('menu', 'helpAndSupport') || 'Help Center',
      icon: <HelpCircle size={18} className="sidebar-menu-icon" />,
      main: false
    }
  ]

  const RenderRoutes = React.useMemo(
    () => (
      <SideBar
        bg="white"
        color="#989898"
        navItems={NAV_ITEMS.filter((item) => {
          if (!isTabletOrMobile && item.to === '/auth/notifications') {
            return false
          }
          return true
        })}
        hoverColor="#1E53691A"
        closeOnNavigate={false}
      >
        {PRIVATE_ROUTES.filter((route) => {
          if (!isTabletOrMobile && route.path === '/auth/notifications') {
            return false
          }
          return true
        }).map((route) => {
          return <PrivateRoute key={route.path} {...route} />
        })}
      </SideBar>
    ),
    [NAV_ITEMS, isTabletOrMobile]
  )

  return (
    <Router>
      <Suspense fallback={<FillLoader />}>
        <Switch>
          {PUBLIC_ROUTES.map((route) => {
            return <PublicRoute key={route.path} {...route} />
          })}
          <Route
            path="/auth"
            render={() => (
              <>
                <OnboardingTour />

                {isTabletOrMobile ? (
                  <>
                    {RenderRoutes}
                    {openNotes && (
                      <MotionFlex
                        w="100%"
                        bottom="0"
                        background="#fff"
                        zIndex={2}
                        boxShadow="1px 2px 4px #C4C4C4;"
                        p="16px"
                        position="absolute"
                        height="100%"
                      >
                        <NotesSideBar />
                      </MotionFlex>
                    )}
                  </>
                ) : (
                  <Flex>
                    {RenderRoutes}
                    {openNotes && (
                      <MotionFlex
                        w="448px"
                        bottom="0"
                        background="#fff"
                        zIndex={1}
                        boxShadow="1px 2px 4px #C4C4C4;"
                        p="16px"
                      >
                        <NotesSideBar />
                      </MotionFlex>
                    )}
                  </Flex>
                )}
              </>
            )}
          />
          <Route render={PageNotFound} />
        </Switch>
      </Suspense>
    </Router>
  )
}

export default Navigation
