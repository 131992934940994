import { Box, Flex, Icon, Image } from '@chakra-ui/core'
import React from 'react'
import { MdInsertDriveFile } from 'react-icons/md'
import { images } from '../../../theme'
import { H3 } from '../../../typography'

type NoteProps = {
  downloadCoach: (note: any) => void
  note: any
  setIsOpen: (isOpen: boolean) => void
}

const Note: React.FC<NoteProps> = ({ downloadCoach, note, setIsOpen }) => {
  if (!note) {
    return null
  }
  return (
    <Box
      bg=" rgba(152, 152, 152, 0.1)"
      borderRadius="8px"
      cursor="pointer"
      flexDir="column"
      m={2}
      p={3}
      h="auto"
      justifyContent="space-between"
      data-testid="note-item"
    >
      <Flex flexDir="row">
        <Icon as={MdInsertDriveFile} w="16px" h="20px" color="#989898" mr={2} />
        <H3
          fontSize={14}
          color="#2F2F2F"
          fontWeight={400}
          textAlign="left"
          pr={5}
          onClick={() => downloadCoach(note?.saboteurProofValue)}
          data-testid="download-note"
        >
          {note?.saboteurProofValue?.name}
        </H3>
        <Image src={images.bin} onClick={() => setIsOpen(true)} data-testid="open-note" />
      </Flex>
    </Box>
  )
}

export default Note
