import { Box } from '@chakra-ui/core'
import { Markup } from 'interweave'
import { truncate } from 'lodash'
import React from 'react'
import { H3, H4 } from '../../../typography'

type CurrentNotesProps = {
  hanldeNoteChange: (note: any) => void
  note: any
}

const CurrentNotes: React.FC<CurrentNotesProps> = ({ hanldeNoteChange, note }) => {
  if (!note) {
    return null
  }
  const MAX_LENGTH = 25
  return (
    <Box
      key={note?.id}
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.2)"
      rounded="md"
      bg="white"
      p={3}
      h={161}
      m={4}
      w={157}
      cursor="pointer"
      onClick={() => hanldeNoteChange(note)}
      data-testid="note-item"
    >
      <H3 fontSize={16} textAlign="left" color="asera.500" fontWeight={600}>
        {truncate(note.name, {
          length: 10,
          omission: '...'
        })}
      </H3>
      <H4 fontSize={16} color="#989898" textAlign="left">
        {note?.note.length > MAX_LENGTH ? (
          <>
            <Markup content={note?.note.substring(0, MAX_LENGTH)} />
            ...
          </>
        ) : (
          <Markup content={note?.note} />
        )}
      </H4>
    </Box>
  )
}

export default CurrentNotes
