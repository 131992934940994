import useSWR from 'swr'
import { requestPost } from '../utils/services'

const useThree60SurveyStats = (
  three60formId?: string,
  userId?: number,
  secid?: number,
  submodsubid?: number
) => {
  const three60SurveyStatKey =
    three60formId &&
    (userId || userId === 0) &&
    (submodsubid || submodsubid === 0) &&
    (secid || secid === 0)
      ? '/three-60-survey-stats'
      : null
  const { data, error } = useSWR(three60SurveyStatKey, (url: string) =>
    requestPost(url, {
      lastSurveySentInformation: {
        userId,
        formId: three60formId,
        submodsubid,
        secid,
        createreport: false
      }
    })
  )

  if (data) {
    const { totalResponses, daysSinceSurveySent } = data
    return {
      totalResponses,
      daysSinceSurveySent,
      isLoading: !data
    }
  }
  return {
    totalResponses: -1,
    daysSinceSurveySent: -1,
    isLoading: !data && !error
  }
}

export default useThree60SurveyStats
