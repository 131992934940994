import { Checkbox, CheckboxProps, Flex } from '@chakra-ui/core'
import styled from '@emotion/styled'
import * as React from 'react'
import { CheckCircle, XCircle } from 'react-feather'
import { ColorProps, SpaceProps } from 'styled-system'
import { Text } from '../../typography'

type CustomCheckBoxProps = CheckboxProps &
  SpaceProps &
  ColorProps & {
    isDisabled?: boolean
    isLoading?: boolean
    option: string
    answer?: string | null
    charIndex: number
    isCorrect?: boolean | null
    isChecked: boolean
    hasAnswer?: boolean
  }

const StyledCheckbox = styled(Checkbox)`
  display: none;
`

const CustomCheckbox: React.FC<CustomCheckBoxProps> = ({
  isDisabled,
  option,
  answer,
  charIndex,
  isCorrect,
  isChecked,
  hasAnswer,
  ...rest
}) => {
  const char = String.fromCharCode(97 + charIndex)

  const Colors = () => {
    if (isCorrect && isChecked && answer && hasAnswer) {
      return '#16BF97'
    } else if (!isCorrect && isChecked && answer && hasAnswer) {
      return '#D0222D'
    } else if (!answer && isChecked && !hasAnswer) {
      return '#9A8C6F'
    } else {
      return 'white'
    }
  }

  function renderIcon() {
    if (isCorrect && answer && hasAnswer) {
      return (
        <CheckCircle
          role="img"
          aria-label="check"
          size={20}
          color="white"
          className="sidebar-menu-icon"
        />
      )
    } else if (!isCorrect && answer && hasAnswer) {
      return (
        <XCircle role="img" aria-label="x" size={20} color="white" className="sidebar-menu-icon" />
      )
    } else {
      return null
    }
  }

  return (
    <>
      {isDisabled ? (
        isChecked && (
          <Flex bg={Colors()} {...rest}>
            <StyledCheckbox />
            <Flex>
              <Text fontWeight="bold" mr={2}>
                {char.toUpperCase()}.
              </Text>
              <Text color="#1A202C">{option}</Text>
            </Flex>
            {renderIcon()}
          </Flex>
        )
      ) : (
        <Flex data-testid="custom-checkbox" bg={Colors()} {...rest}>
          <StyledCheckbox />
          <Flex>
            <Text color={isChecked ? 'white' : '#1A202C'} fontWeight="bold" mr={2}>
              {char.toUpperCase()}.
            </Text>
            <Text color={isChecked ? 'white' : '#1A202C'}>{option}</Text>
          </Flex>
          {renderIcon()}
        </Flex>
      )}
    </>
  )
}

export default CustomCheckbox

CustomCheckbox.defaultProps = {
  mt: 3,
  borderWidth: 1,
  borderColor: 'gray',
  borderRadius: 5,
  alignItems: 'center',
  p: 2,
  justifyContent: 'space-between'
}
