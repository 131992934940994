import { Badge, Flex } from '@chakra-ui/core'
import { AnimatePresence } from 'framer-motion'
import * as React from 'react'
import { useMediaQuery } from 'react-responsive'
import { useAppContext } from '../../../context/AppProvider'
import { Text } from '../../../typography'
import { MenuItem, Tooltip } from './styles'

type SideBarItemProps = {
  title: string
  to: string
  color: string
  hoverColor: string
  icon: React.ReactNode
  tooltipColor?: string
  tooltipBg?: string
  closeOnNavigate?: boolean
  isNotification?: boolean
}

const SideBarItem: React.FC<SideBarItemProps> = ({
  color,
  hoverColor,
  icon,
  title,
  to,
  tooltipColor,
  tooltipBg,
  closeOnNavigate,
  isNotification
}) => {
  const { drawerOpen, toggleDrawer, notiticationCount } = useAppContext()

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 40em)' })

  const variants = {
    open: {
      x: 0,
      transition: {
        x: { stiffness: 200, velocity: -100 }
      }
    },
    closed: {
      x: isTabletOrMobile ? -50 : 0,
      transition: {
        x: { stiffness: 200 }
      }
    }
  }

  return (
    <MenuItem
      to={to}
      color={color}
      variants={variants}
      hovercolor={hoverColor}
      onClick={() => {
        if (closeOnNavigate && drawerOpen) {
          toggleDrawer()
        }
      }}
      activeClassName="active-nav-link"
    >
      <Flex
        height="20px"
        alignItems="center"
        justifyContent="center"
        className="sidebar-nav-item-wrapper"
      >
        <Flex className="icon-wrap">
          {icon}
          {isNotification && notiticationCount > 0 && (
            <Badge
              variant="solid"
              variantColor="lucaniaOrange"
              borderRadius="50%"
              top="-8px"
              left="-10px"
              position="relative"
              fontSize="10px"
              width="14px"
              height="14px"
            >
              <Flex justifyContent="center" alignItems="center">
                {notiticationCount}
              </Flex>
            </Badge>
          )}
        </Flex>
        <AnimatePresence>
          {drawerOpen && (
            <Text
              ml={isNotification && notiticationCount > 0 ? 0 : 4}
              fontSize="14px"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, pointerEvents: 'none' }}
            >
              {title}
            </Text>
          )}
        </AnimatePresence>
        {!drawerOpen && !isTabletOrMobile && (
          <Tooltip bg={tooltipBg || 'gray.800'} className="sidebar-tooltip">
            <Text fontSize={13} color={tooltipColor || 'white'}>
              {title}
            </Text>
          </Tooltip>
        )}
      </Flex>
    </MenuItem>
  )
}

export default SideBarItem
