import useSWR from 'swr'
import { requestGet } from '../utils/services'

const useUserProgrammes = (userId?: any) => {
  const userProgrammesKey = `/user-programmes/${userId}`
  const { data, mutate, isValidating } = useSWR(
    userProgrammesKey,
    (url: string) => requestGet(url),
    { revalidateOnFocus: false }
  )

  if (data) {
    const { userProgrammes } = data
    return {
      userProgrammes,
      updateProgrammes: () => mutate(),
      isValidating: isValidating
    }
  }
  return {
    userProgrammes: data?.userProgrammes,
    updateProgrammes: () => mutate(),
    isValidating: !data
  }
}

export default useUserProgrammes
