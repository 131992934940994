import React from 'react'
import { Notes } from '../../../generated/graphql'
import { truncate } from 'lodash'
import { Box } from '@chakra-ui/core'
import { H3 } from '../../../typography'

type RecentNotesProps = {
  notes: Notes[]
  handleNoteChange: (note: Notes) => void
}
const RecentNotes: React.FC<RecentNotesProps> = ({ notes, handleNoteChange }) => {
  return (
    <>
      {notes && notes.length > 0
        ? notes.map((orderedNote) => (
            <Box
              key={orderedNote?.id}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.2)"
              rounded="md"
              bg="white"
              p={3}
              m={3}
              h={161}
              w={157}
              onClick={() => handleNoteChange(orderedNote)}
              cursor="pointer"
              data-testid="note-item"
            >
              <H3 fontSize={16} fontWeight={600} textAlign="center" color="asera.500">
                {truncate(orderedNote.programme?.name!, {
                  length: 22,
                  omission: '...'
                }) ||
                  truncate(orderedNote.name!, {
                    length: 22,
                    omission: '...'
                  })}
              </H3>

              {/* <H4 fontSize={16} color="#989898" textAlign="left">
            <Markup content={orderedNote?.note!} />
          </H4> */}
            </Box>
          ))
        : null}
    </>
  )
}

export default RecentNotes
