import { fetchJwt } from '..'

export const requestGet = async (url: string) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_HOST}${url}`, {
      method: 'get',
      headers: {
        authorization: `Bearer ${fetchJwt()}`
      }
    })
    if (res.ok) {
      return res.json()
    }
    throw new Error('Failed fetching resource')
    // eslint-disable-next-line
  } catch(error) {
    console.log(error)
  }
}

export const requestPost = async (
  url: string,
  payload: any,
  onError?: (erroMessage?: string) => void
) => {
  try {
    const res =
      url === '/auth/local'
        ? await fetch(`${process.env.REACT_APP_API_HOST}${url}`, {
            method: 'post',
            body: JSON.stringify(payload)
          })
        : await fetch(`${process.env.REACT_APP_API_HOST}${url}`, {
            method: 'post',
            headers: {
              authorization: `Bearer ${fetchJwt()}`
            },
            body: JSON.stringify(payload)
          })

    if (!res.ok && onError) {
      const { data } = await res.json()
      onError(data[0].messages[0].message)
      return
    }
    return res.json()
  } catch (error) {
    if (onError) {
      onError()
    }
    console.error('Failed post request....', error)
  }
}

export const requestPut = async (url: string, payload: any, onSuccess?: (data: any) => void) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_HOST}${url}`, {
      method: 'put',
      headers: {
        authorization: `Bearer ${fetchJwt()}`
      },
      body: JSON.stringify(payload)
    })

    if (res.ok) {
      if (onSuccess) {
        onSuccess(res.json())
      }
      return res.json()
    }
    throw new TypeError('Failed POST request')
  } catch (error) {
    console.log(error)
  }
}

/**
 * The following are api calls for the server operations
 */
export const updateSectionSubmoduleSubmission = async ({
  subModuleSubmissionId,
  payload,
  onSuccess,
  onError
}: {
  subModuleSubmissionId: string
  payload: any
  onSuccess: () => void
  onError?: (errorMesage: string) => void
}) => {
  try {
    const { submoduleSubmissionData } = payload
    if (
      (submoduleSubmissionData.programme || submoduleSubmissionData.programme === 0) &&
      (submoduleSubmissionData.sub_module || submoduleSubmissionData.sub_module === 0)
    ) {
      await requestPut(`/submodule-submission/${subModuleSubmissionId}`, payload)
      onSuccess()
    } else if (onError) {
      if (!(submoduleSubmissionData.programme || submoduleSubmissionData.programme === 0)) {
        onError('Programme is missing from your account, please contact administrator')
      } else {
        onError('Submodule is missing from your account, please contact administrator')
      }
    }
  } catch (error) {
    if (onError) {
      onError('Failed updating submodule submission, check your internet')
    }
    console.log(error)
  }
}
