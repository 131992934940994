import { Flex } from '@chakra-ui/core'
import { truncate } from 'lodash'
import * as React from 'react'
import { Link, useParams } from 'react-router-dom'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import { useAppContext } from '../../context/AppProvider'
import { PRIVATE_ROUTES } from '../../navigation/routes'
import { Text } from '../../typography'
import { DisplayLanguage } from '../../utils/languageHelpers'
import useSection from '../../hooks/useSection'

export const Breadcrumbs = () => {
  const routes = PRIVATE_ROUTES.map((route) => {
    if (route.path === '/auth/dashboard') {
      return {
        ...route,
        breadcrumb: DisplayLanguage('menu', 'home') || 'Dashboard'
      }
    } else if (route.path === '/auth/programmes') {
      return {
        ...route,
        breadcrumb: DisplayLanguage('menu', 'myProgramme') || 'Programmes'
      }
    } else if (route.path === '/auth/mylibrary') {
      return {
        ...route,
        breadcrumb: DisplayLanguage('menu', 'myLibrary') || 'My Library'
      }
    } else if (route.path === '/auth/mywork') {
      return {
        ...route,
        breadcrumb: DisplayLanguage('menu', 'myWork') || 'My Work'
      }
    } else if (route.path === '/auth/myprofile') {
      return {
        ...route,
        breadcrumb: DisplayLanguage('menu', 'myProfile') || 'My Profile'
      }
    } else if (route.path === '/auth/helpandsupport') {
      return {
        ...route,
        breadcrumb: DisplayLanguage('menu', 'helpAndSupport') || 'Help Center'
      }
    }
    return route
  })

  const breadcrumbs = useBreadcrumbs(routes, { disableDefaults: true })

  const {
    myWorkTitle,
    programmeDetails,
    selectedProgramme,
    setSelectedProgramme,
    programmeNote,
    setProgrammeNote,
    viewDocument,
    setViewDocument,
    noteName,
    setNoteName
  } = useAppContext()
  const { sectionId, subModuleSubmissionId } = useParams<{
    sectionId: string
    subModuleSubmissionId: string
  }>()
  const { section: sectionDetails } = useSection(sectionId)

  return (
    <Flex data-testid="breadcrumbs" style={{ alignItems: 'center' }}>
      {breadcrumbs.map(({ breadcrumb, match }, index) => {
        const ml = index === 0 ? 0 : 2
        const isLastBreadCrumbMenuText = breadcrumbs.length - index === 1 // text menu
        const isLastBreadCrumbArrow = breadcrumbs.length - index === 2 // '>' arrow menu
        const getBreadcrumbText = () => {
          if (myWorkTitle && match.url === '/auth/mywork') {
            return myWorkTitle.title
          } else if (programmeDetails && match.url === `/auth/programmes/${programmeDetails.id}`) {
            return truncate(programmeDetails.name, { length: 25, separator: '' })
          } else if (
            sectionDetails &&
            match.url ===
              `/auth/programmes/${sectionDetails.programmeId}/submission/${sectionDetails.progSubId}/section/${sectionDetails.id}/submodulesubmission/${subModuleSubmissionId}`
          ) {
            return truncate(sectionDetails.name, { length: 25, separator: '' })
          } else {
            setSelectedProgramme(null)
            setProgrammeNote(null)
            setViewDocument(false)
            setNoteName(null)
            return breadcrumb
          }
        }
        return (
          <React.Fragment key={match.url}>
            <Link to={match.url || ''}>
              <Text
                style={{
                  textTransform: 'capitalize',
                  fontWeight: 'bold',
                  opacity: `${isLastBreadCrumbMenuText ? 1 : 0.5}`,
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden'
                }}
                fontSize="14px"
                ml={ml}
                mr={2}
                mb={0}
                onClick={() => {
                  if (selectedProgramme) {
                    setSelectedProgramme(null)
                  }
                  if (programmeNote) {
                    setProgrammeNote(null)
                  }
                  if (viewDocument) {
                    setViewDocument(false)
                  }
                  if (noteName) {
                    setNoteName(null)
                  }
                }}
              >
                {getBreadcrumbText()}
                {programmeNote && myWorkTitle && myWorkTitle.id === 0 && (
                  <>
                    <span style={{ opacity: isLastBreadCrumbArrow ? 1 : 0.5 }}>{' > '}</span>
                    {programmeNote}
                  </>
                )}
                {selectedProgramme && myWorkTitle && myWorkTitle.id === 1 && (
                  <>
                    <span style={{ opacity: isLastBreadCrumbArrow ? 1 : 0.5 }}>{' > '}</span>
                    {selectedProgramme}
                  </>
                )}
                {viewDocument && myWorkTitle && myWorkTitle.id === 2 && (
                  <>
                    <span style={{ opacity: isLastBreadCrumbArrow ? 1 : 0.5 }}>{' > '}</span>
                    View
                  </>
                )}
              </Text>
            </Link>
            {index < breadcrumbs.length - 1 && (
              <span style={{ opacity: isLastBreadCrumbArrow ? 1 : 0.5 }}>{' > '}</span>
            )}
          </React.Fragment>
        )
      })}
    </Flex>
  )
}

export default Breadcrumbs
