import React from 'react'
import {
  Accordion,
  AccordionHeader,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Stack,
  Text
} from '@chakra-ui/core'

type LucaniaAccordionProps = {
  title: string | React.ReactNode
  icon?: React.ReactNode
  children: React.ReactNode
}

const LucaniaAccordion: React.FC<LucaniaAccordionProps> = ({ title, icon, children }) => {
  return (
    <Accordion w="100%" borderRadius="12px" allowMultiple paddingBottom="15px">
      <AccordionItem borderStyle="none">
        <AccordionHeader
          borderStyle="none"
          _expanded={{ bg: '#115264', color: '#FFFFFF' }}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.2)"
          borderRadius="8px"
          paddingTop="23px"
          data-testid="accordion-header"
        >
          <Stack flexDir="row" pb={2} w="100%" justifyContent="space-between" align="center">
            <Stack flex="row">
              <Text fontWeight={500} fontSize="18px" lineHeight="26px">
                {title}
              </Text>
              {icon}
            </Stack>
            <AccordionIcon />
          </Stack>
        </AccordionHeader>

        <AccordionPanel data-testid="accordion-content">{children}</AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}
export default LucaniaAccordion
