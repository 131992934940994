import useSWR from 'swr'
import { requestGet } from '../utils/services'

const useFeedforwardStats = (feedfoward_url_id?: number) => {
  const formKey = feedfoward_url_id ? `/feedforward-responses/${feedfoward_url_id}` : null
  const { data } = useSWR(formKey, (url: string) => requestGet(url))

  if (data) {
    const { feedforwardTotalResponses } = data
    return {
      feedforwardTotalResponses,
      isLoading: false
    }
  }
  return {
    feedforwardTotalResponses: -1,
    isLoading: true
  }
}

export default useFeedforwardStats
