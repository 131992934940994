import * as React from 'react'
import { useHistory } from 'react-router'
import MotionFlex from '../MotionFlex'
import {
  CircularProgress,
  CircularProgressLabel,
  IconButton,
  Stat,
  StatArrow,
  StatHelpText
} from '@chakra-ui/core'
import { Article, UsersPermissionsUser } from '../../generated/graphql'
import { H3, H5, Text } from '../../typography'
import { DisplayLanguage } from '../../utils/languageHelpers'

export type HomeLibraryProps = {
  afterCount: number
  beforeCount: number
  valueArticles?: number
  user?: UsersPermissionsUser
  articles?: Article[]
}

const HomeLibrary: React.FC<HomeLibraryProps> = ({
  afterCount,
  beforeCount,
  valueArticles,
  user,
  articles
}) => {
  const history = useHistory()
  return (
    <MotionFlex
      mt={5}
      p={5}
      flexDir="column"
      shadow="md"
      borderWidth="1px"
      bg="white"
      width={['100%', 'unset']}
    >
      <H5>{DisplayLanguage('homeScreen', 'myLibrary')}</H5>
      <MotionFlex mt={5} flexDir="row" justifyContent="space-between">
        <Stat>
          <H3>{DisplayLanguage('homeScreen', 'viewedArticles')}</H3>
          <StatHelpText>
            <StatArrow type={afterCount > beforeCount ? 'increase' : 'decrease'} />
            {afterCount > beforeCount || 0
              ? `${afterCount} ${DisplayLanguage('homeScreen', 'changesUp') || 'up'}`
              : `${beforeCount} ${DisplayLanguage('homeScreen', 'changesDown') || 'down'} `}
            from last week.
          </StatHelpText>
        </Stat>
        <CircularProgress ml={5} value={valueArticles || 0} size="100px" color="green">
          <CircularProgressLabel>
            {user?.articlesViewed?.length || 0}/{articles?.length}
          </CircularProgressLabel>
        </CircularProgress>
      </MotionFlex>
      <MotionFlex mt={5} flexDir="row" justifyContent="space-between" alignItems="center">
        <Text>{DisplayLanguage('homeScreen', 'viewLibrary')}</Text>
        <IconButton
          aria-label="View"
          icon="arrow-forward"
          onClick={() => history.push('/auth/mylibrary')}
        />
      </MotionFlex>
    </MotionFlex>
  )
}

export default HomeLibrary

HomeLibrary.defaultProps = {
  afterCount: 0,
  beforeCount: 0
}
