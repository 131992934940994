import { Flex, FlexProps } from '@chakra-ui/core'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router'
import { Header } from '../components'
import { useAuthContext } from '../context/AuthProvider'

type PageWrapProps = FlexProps & {
  title: string
  name?: string
  hideHeader?: boolean
}

const PageWrap: React.FC<PageWrapProps> = ({ children, title, name, hideHeader, ...rest }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const { user } = useAuthContext()

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 40em)' })

  const { push, location } = useHistory()

  React.useEffect(() => {
    const getHelp = sessionStorage.getItem('getHelp')
    const paths = [
      '/auth/helpandsupport',
      '/auth/helpandsupport/faqs',
      '/auth/helpandsupport/aboutus',
      '/auth/helpandsupport/privacy-policy',
      '/auth/helpandsupport/terms-of-service'
    ]
    if (
      user &&
      getHelp &&
      !paths.includes(location?.pathname) &&
      (!user?.firstName || !user?.lastName)
    ) {
      push('/auth/onboarding/details')
    }
  }, [user, location, push])

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Flex {...rest} px={isTabletOrMobile ? '16px' : '64px'}>
        {!hideHeader && <Header name={name} />}
        {children}
      </Flex>
    </>
  )
}

PageWrap.defaultProps = {
  width: '100%',
  py: '28px',
  mt: '64px',
  bg: '#FFFFFF',
  height: '100%',
  flexDir: 'column',
  justify: 'flex-start'
}

export default PageWrap
