import {
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement
} from '@chakra-ui/core'
import { InputProps } from '@chakra-ui/core/dist/Input'
import { useField } from 'formik'
import * as React from 'react'
import { AlertCircle, Eye, EyeOff } from 'react-feather'
import { Text } from '../../../typography'
import { LabelProps } from '../styles'

export type ConnectedFormGroupProps = LabelProps &
  InputProps & {
    label?: string
    name: string
  }

const ConnectedFormGroup: React.FC<ConnectedFormGroupProps> = ({ label, type, ...rest }) => {
  const [field, meta] = useField(rest.name)
  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)
  const isError = meta.touched && meta.error
  return (
    <Flex flexDirection="column" width="100%" mr={rest.mr} ml={rest.ml} mt={rest.mt} mb={rest.mb}>
      <FormControl>
        {label && <FormLabel htmlFor={field.name}>{label}</FormLabel>}
        <InputGroup size="md" pr={-4}>
          <Input
            mb={2}
            mr={3}
            focusBorderColor="accent.500"
            type={show ? 'text' : type}
            {...field}
            {...rest}
            id={field.name}
            {...rest}
            isInvalid={isError ? true : false}
          />
          <InputRightElement pr={2}>
            {!isError ? (
              <Icon
                size="19px"
                onClick={handleClick}
                as={show ? EyeOff : Eye}
                color={show ? 'secondary.500' : 'primary.400'}
              />
            ) : (
              <AlertCircle color="red" size="15px" />
            )}
          </InputRightElement>
        </InputGroup>
        {isError ? (
          <Flex>
            <Text color="red.500" textAlign="center" fontSize="12px" margin="auto">
              {meta.error}
            </Text>
          </Flex>
        ) : null}
      </FormControl>
    </Flex>
  )
}

export default ConnectedFormGroup

ConnectedFormGroup.defaultProps = {
  mb: 2,
  type: 'password'
}
