import { Flex, FlexProps } from '@chakra-ui/core'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import { Header } from '../components'

type PageWrapProps = FlexProps & {
  title: string
  name?: string
  hideHeader?: boolean
}

const PageWrapForLogin: React.FC<PageWrapProps> = ({
  children,
  title,
  name,
  hideHeader,
  ...rest
}) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Flex {...rest}>
        {!hideHeader && <Header name={name} />}
        {children}
      </Flex>
    </>
  )
}

PageWrapForLogin.defaultProps = {
  p: 4,
  display: 'flex',
  pt: 'calc(64px + 1rem)',
  bg: '#115264',
  height: '100%',
  flexDir: 'row',
  minHeight: '100vh',
  justify: 'flex-start'
}

export default PageWrapForLogin
