import { Button, Box } from '@chakra-ui/core'
import React from 'react'
import { useHistory } from 'react-router'
import { Text } from '../../typography'
import { useMediaQuery } from 'react-responsive'

const JoyRideButton = ({ text, bgHover, ...rest }: any) => (
  <Button
    {...rest}
    _focus={{
      boxShadow: 'none'
    }}
    _hover={{
      bg: bgHover
    }}
  >
    {text}
  </Button>
)

const JoyRideCustomComponent = ({
  continuous,
  index,
  step,
  skipProps,
  closeProps,
  primaryProps,
  tooltipProps
}: any) => {
  const history = useHistory()
  const isMobile = useMediaQuery({ query: '(max-width: 48em)' })

  return (
    <Box
      {...tooltipProps}
      width={isMobile ? '380px' : '400px'}
      background="white"
      padding="1rem"
      borderRadius="8px"
      beaconSize={360}
    >
      {step.title && (
        <>
          <Text color="#115264" fontSize={index === 0 ? '22px' : '16px'} fontWeight={600}>
            {step.title}
          </Text>
          <br />
        </>
      )}
      {step.content && (
        <Box pt={index === 0 ? '10px' : '2px'} pb={index === 0 ? '26px' : '20px'}>
          <Text color="#989898" fontSize={index === 0 ? '16px' : '14px'}>
            {step.content}
          </Text>
        </Box>
      )}
      <Box textAlign="end">
        {index === 1 && (
          <JoyRideButton
            {...skipProps}
            bg="white"
            bgHover="white"
            border="none"
            mr="10px"
            color="#115264"
            fontWeight="normal"
            text="Skip"
          />
        )}
        {step.target === 'body' && (
          <JoyRideButton
            {...skipProps}
            border="1px solid #115264"
            borderRadius="4px"
            bg="white"
            p="8px 1rem"
            mr="10px"
            bgHover="white"
            text="See Tutorial"
            color="#115264"
            fontWeight={500}
            onClick={() => history.push('/download-app')}
          />
        )}
        {continuous && index !== 5 && (
          <JoyRideButton
            {...primaryProps}
            bg="#115264"
            bgHover="#115264"
            border="none"
            color="white"
            text={index === 0 ? 'Continue here' : 'Next'}
            p="8.5px 20px"
            fontWeight={500}
          />
        )}
        {(!continuous || index === 5) && (
          <JoyRideButton
            {...closeProps}
            bg="#115264"
            bgHover="#115264"
            border="none"
            color="white"
            text="Get Started"
          />
        )}
      </Box>
    </Box>
  )
}

export default JoyRideCustomComponent
