import styled from '@emotion/styled'
import * as React from 'react'
import { MotionFlex } from '..'
import Version from '../Version'
import { useMediaQuery } from 'react-responsive'

const PanelWrapper = styled(MotionFlex)`
  width: 40%;
  display: flex;

  overflow-y: auto;
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  @media screen and (max-width: 910px) {
    background-color: white;
    width: 100%;
    margin: 0px;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`
const SideSlider: React.FC = ({ children }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 9108px)' })
  return (
    <PanelWrapper
      p={5}
      m={[5, 0]}
      bg="white"
      rounded={['md', 0]}
      initial={isTabletOrMobile ? 'visible' : 'hidden'}
    >
      {children}
      <Version />
    </PanelWrapper>
  )
}

export default SideSlider
