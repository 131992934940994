export default {
  bg: require('../assets/images/core-bg.jpg'),
  sovtechLogo: require('../assets/images/sovtech-small.png'),
  404: require('../assets/images/404.svg'),
  noData: require('../assets/images/no-data.png'),
  oddoBHFLogo: require('../../src/assets/images/oddoBHFLogo.svg'),
  LucaniaLogoRed: require('../../src/assets/images/LucaniaLogoRed.svg'),
  lucaniaLogo: require('../../src/assets/images/lucianaLogo.jpg'),
  aesaraPartnersLogo: require('../assets/images/aesaraPartnersLogo.svg'),
  welcomeImage: require('../../src/assets/images/welcome.svg'),
  detailsImage: require('../../src/assets/images/details.svg'),
  successImage: require('../../src/assets/images/success.svg'),
  myWorkImage: require('../../src/assets/images/myWork.svg'),
  microsoft: require('../assets/images/microsoft.png'),
  aesaraGif: require('../assets/images/Aesara-Lucania-2.gif'),
  ellipse1: require('../../src/assets/images/Ellipse 4.svg'),
  ellipse2: require('../../src/assets/images/Ellipse 7.svg'),
  ellipse3: require('../../src/assets/images/Ellipse 8.svg'),
  ellipseYellow: require('../assets/images/Ellipseyellow.svg'),
  ellipseGrey: require('../assets/images/Ellipsegrey.svg'),
  mrsimage: require('../assets/images/mrsimage.svg'),
  rectangle75: require('../assets/images/Rectangle 75.svg'),
  jodie: require('../assets/images/jodie.svg'),
  ellipse4: require('../assets/images/Ellipsegrey4.svg'),
  Lady1: require('../assets/images/lady1.svg'),
  rectangleyellow74: require('../assets/images/Rectangleyellow74.svg'),
  redCircle: require('../assets/images/circle.svg'),
  ellipseTopRight: require('../assets/images/ellipseTopRight.svg'),
  arrowNextIcon: require('../assets/images/arrowNextIcon.svg'),
  downloadAppIosStep1: require('../assets/images/downloadApp/iOS/step1.svg'),
  downloadAppIosStep2: require('../assets/images/downloadApp/iOS/step2.svg'),
  downloadAppIosStep3: require('../assets/images/downloadApp/iOS/step3.svg'),
  downloadAppIosStep4: require('../assets/images/downloadApp/iOS/step4.svg'),
  downloadAppAndroidStep1: require('../assets/images/downloadApp/android/step1.svg'),
  downloadAppAndroidStep2: require('../assets/images/downloadApp/android/step2.svg'),
  downloadAppAndroidStep3: require('../assets/images/downloadApp/android/step3.svg'),
  downloadAppAndroidStep4: require('../assets/images/downloadApp/android/step4.svg'),
  addNote: require('../assets/images/addnote.svg'),
  moduleComplete: require('../assets/images/moduleComplete.svg'),
  upload: require('../assets/images/upload.svg'),
  pdfIcon: require('../assets/images/Rectangle 125.svg'),
  file: require('../assets/images/Vector.svg'),
  darkTrash: require('../assets/images/darkTrash.svg'),
  notePad: require('../assets/images/Notepad.svg'),
  getStarted: require('../assets/images/getStarted.svg'),
  getStartedMobile: require('../assets/images/getStartedMobile.png'),
  textQuote: require('../assets/images/textQuote.svg'),
  dashboardImage: require('../assets/images/dashboardImage.svg'),
  notepadPen: require('../assets/images/Notespad.svg'),
  bin: require('../assets/images/Bin.svg'),
  newAesaraLogo: require('../assets/images/aesaranew.svg'),
  nodata: require('../assets/images/nodata.svg'),
  emptyNotes: require('../assets/images/EmptyNotepad.svg'),
  emptyModule: require('../assets/images/EmptyModule.svg'),
  sectionModuleComplete: require('../assets/images/moduleComplete.png'),
  sectionEventsComplete: require('../assets/images/scheduleImage.png'),
  footer: require('../assets/images/Group 232.svg')
}
