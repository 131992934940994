import { chunk } from 'lodash'
import * as React from 'react'
import { SpaceProps, WidthProps } from 'styled-system'
import { Col, Container, Row } from '../ResponsiveGrid'

type GridGeneratorProps = SpaceProps & {
  cols: 1 | 2 | 3 | 4 | 6 | 12
} & WidthProps

const GridGenerator: React.FC<GridGeneratorProps> = ({ cols, children, ...rest }) => {
  const colWidth = 12 / cols

  const rows = chunk(React.Children.toArray(children), cols)

  return (
    <Container {...rest}>
      {rows.map((cols, i) => (
        <Row data-testid="grid-row" key={i}>
          {cols.map((col, i) => (
            <Col key={i} sm={12} md={colWidth} data-testid="grid-column">
              {col}
            </Col>
          ))}
        </Row>
      ))}
    </Container>
  )
}

export default GridGenerator
