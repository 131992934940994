import { Divider, Icon } from '@chakra-ui/core'
import React from 'react'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { useMediaQuery } from 'react-responsive'
import { H2, H4 } from '../../../typography'
import MotionFlex from '../../MotionFlex'

type GroupedNotesProps = {
  handleProgrammeNote: (noteList: any[], name: string) => void
  noteList: any[]
  name: string
}

const GroupedNotes: React.FC<GroupedNotesProps> = ({ handleProgrammeNote, noteList, name }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 40em)' })

  return (
    <React.Fragment>
      {isTabletOrMobile ? (
        <>
          <MotionFlex flexDir="row" h={53} p="16px" w="100%" data-testid="grouped-note">
            <MotionFlex
              w="90%"
              flexDir="row"
              justifyContent="space-between"
              onClick={() => handleProgrammeNote(noteList, name)}
              cursor="pointer"
              data-testid="programme-note"
            >
              <H2 color="#2F2F2F" fontSize="16px" fontWeight={400}>
                {!name || name === 'undefined' ? 'Notes' : name}
              </H2>
            </MotionFlex>
            <MotionFlex cursor="pointer" onClick={() => handleProgrammeNote(noteList, name)}>
              <H4 fontSize={16} fontWeight={400} style={{ cursor: 'pointer' }}>
                {' '}
                {noteList.length}
              </H4>
              <Icon
                as={MdKeyboardArrowRight}
                color="asera.500"
                fontWeight={900}
                size="24px"
                fontStyle="bold"
                cursor="pointer"
                onClick={() => handleProgrammeNote(noteList, name)}
              />
            </MotionFlex>
          </MotionFlex>
          <Divider color="#989898" width="100%" h={1} m={0} />
        </>
      ) : (
        <>
          <MotionFlex
            flexDir="row"
            h={53}
            p="16px"
            w="100%"
            alignItems="center"
            data-testid="grouped-note"
          >
            <MotionFlex w="5%" alignItems="center"></MotionFlex>
            <MotionFlex
              w="90%"
              flexDir="row"
              justifyContent="space-between"
              onClick={() => handleProgrammeNote(noteList, name)}
              cursor="pointer"
              data-testid="programme-note"
            >
              <H2
                color="#2F2F2F"
                fontSize="16px"
                fontWeight={400}
                onClick={() => handleProgrammeNote(noteList, name)}
              >
                {!name || name === 'undefined' ? 'Notes' : name}
              </H2>
            </MotionFlex>
            <MotionFlex w="17%" alignItems="center">
              <H4
                fontSize={16}
                fontWeight={400}
                onClick={() => handleProgrammeNote(noteList, name)}
                style={{ cursor: 'pointer' }}
              >
                {' '}
                {noteList.length} notes created
              </H4>
              <Icon
                as={MdKeyboardArrowRight}
                color="asera.500"
                fontWeight={900}
                size="24px"
                fontStyle="bold"
                cursor="pointer"
                onClick={() => handleProgrammeNote(noteList, name)}
              />
            </MotionFlex>
          </MotionFlex>
          <Divider color="#989898" width="100%" h={1} m={0} />
        </>
      )}
    </React.Fragment>
  )
}

export default GroupedNotes
