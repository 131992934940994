import { Badge, Box } from '@chakra-ui/core'
import { truncate } from 'lodash'
import React from 'react'
import { ChevronRight } from 'react-feather'
import { H3, H4 } from '../../../typography'
import MotionFlex from '../../MotionFlex'

type DocumentProps = {
  handlecoachReport: (items: any[], id: string) => void
  document: any
}

const CurrentDocument: React.FC<DocumentProps> = ({ handlecoachReport, document }) => {
  if (!document) {
    return null
  }
  return (
    <Box
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.2)"
      rounded="md"
      bg="white"
      cursor="pointer"
      flexDir="row"
      p={3}
      m={2}
      h="72px"
      w="100%"
      onClick={() => handlecoachReport(document.items, document?.id)}
      data-testid="document-item"
    >
      <MotionFlex flexDir="row" justifyContent="right">
        <Badge
          ml="1"
          bg={document.label === 'Lucania' ? '#F4A807' : '#9E0000'}
          p={1}
          color="#fff"
          w={76}
        >
          {document.label}
        </Badge>
        <ChevronRight />
      </MotionFlex>

      <H3
        fontSize={16}
        color="asera.500"
        fontWeight={600}
        style={{ marginTop: '-25px' }}
        textAlign="left"
      >
        {truncate(document?.name, {
          length: 22,
          omission: '...'
        })}
      </H3>
      <H4 fontSize={14} color="#989898" fontWeight={400} textAlign="left">
        {document.items?.length + ' '}
        Documents
      </H4>
    </Box>
  )
}

export default CurrentDocument
