import * as React from 'react'

type NotesContextType = {
  openNotes: boolean
  setOpenNotes: (openNotes: boolean) => void
}

export const NotesContext = React.createContext<NotesContextType>({
  openNotes: false,
  setOpenNotes: () => null
})

export const useNotesContext = () => React.useContext(NotesContext)

const NotesProvider: React.FC<{}> = ({ children }) => {
  const [openNotes, setOpenNotes] = React.useState(false)

  return (
    <NotesContext.Provider
      value={{
        openNotes,
        setOpenNotes
      }}
    >
      {children}
    </NotesContext.Provider>
  )
}

export default NotesProvider
