import { Flex, FormControl, FormLabel, Textarea } from '@chakra-ui/core'
import { InputProps } from '@chakra-ui/core/dist/Input'
import { useField } from 'formik'
import * as React from 'react'
import ResizeTextarea from "react-textarea-autosize";
import { Text } from '../../../typography'
import { LabelProps } from '../styles'
import { Markup } from 'interweave';

export type ConnectedTextareaProps = LabelProps &
  InputProps & {
    label?: string
    name: string
  }

const ConnectedTextarea: React.FC<ConnectedTextareaProps> = ({ label, ...rest }) => {
  const [field, meta] = useField(rest.name)

  return (
    <Flex flexDirection="column" width="100%" mr={rest.mr} ml={rest.ml} mt={rest.mt} mb={rest.mb}>
      <FormControl>
        {label && <FormLabel htmlFor={field.name}><Markup content={label} /></FormLabel>}
        <Textarea
          focusBorderColor="accent.500"
          {...field}
          id={field.name}
          {...rest}
          css={rest.css ? rest.css : { overflow: 'auto' }}
          resize="none"
          as={ResizeTextarea}
          minH="unset"
          maxH="300px"
        />
        {meta.touched && meta.error ? (
          <Text color="oddoRed.500" textAlign="right">
            {meta.error}
          </Text>
        ) : null}
      </FormControl>
    </Flex>
  )
}

export default ConnectedTextarea

ConnectedTextarea.defaultProps = {
  mb: 2,
  type: 'text'
}
