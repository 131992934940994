import { Image, Button } from '@chakra-ui/core'
import * as React from 'react'
import { useHistory } from 'react-router'
import { images } from '../../theme'
import { H2, Text } from '../../typography'
import Card from '../Card'
import RevealFlex from '../RevealFlex/index'

type ModuleSuccessProps = {
  title?: string
  subTitle?: string
}

const ModuleSuccess: React.FC<ModuleSuccessProps> = () => {
  const history = useHistory()
  return (
    <>
      <Card
        p={4}
        flex={1}
        width="100%"
        align="center"
        maxWidth="100%"
        justify="center"
        flexDirection="column"
      >
        <RevealFlex>
          <Image src={images.successImage} width="300px" maxWidth="100%" height="auto" />
          <H2 fontWeight="bold">Great Work!</H2>
          <Text textAlign={'center'}>
            You have submitted your report. Your coach will contact you via email within the next 3
            days.
          </Text>
          <br />
          <Text textAlign={'center'}>
            You can also view all of your test &amp; practice submissions in the "My Work" section
            under the menu.
          </Text>
          <Button
            p={3}
            mt={5}
            width="100%"
            variantColor="brand"
            fontWeight="normal"
            onClick={() => history.push(`/auth/myprogramme`)}
          >
            Done
          </Button>
        </RevealFlex>
      </Card>
    </>
  )
}

export default ModuleSuccess
