import * as React from 'react'
import styled from '@emotion/styled'
import { Box, BoxProps } from '@chakra-ui/core'

const Container: React.FC<BoxProps> = (props) => {
  return <ContainerDiv>{props.children}</ContainerDiv>
}
const ContainerDiv = styled(Box)`
  max-width: 700px;
  margin: 0 auto;
`

export default Container
