import { Flex } from '@chakra-ui/core'
import { AnimatePresence, motion } from 'framer-motion'
import * as React from 'react'
import { Check } from 'react-feather'
import { OuterCircle, SpacerLine, Square } from './styles'

type TimelineCheckProps = {
  programmeData: {
    progress: number
    height: number
    id: string
  }[]
}

const AnimatedCheck = motion.custom(Check)

const TimelineCheck: React.FC<TimelineCheckProps> = ({ programmeData }) => {
  return (
    <Flex
      p={4}
      flexDirection="column"
      alignItems="center"
      justifyContent={programmeData.length === 1 ? 'flex-end' : 'space-between'}
    >
      {programmeData.map((programme, i) => {
        const isLastStep = i === programmeData.length - 1
        return (
          <React.Fragment key={programme.id}>
            {programme.progress === 100 ? (
              <Square>
                <OuterCircle bg="green.300" data-testid="outer-circle-green">
                  <AnimatedCheck
                    size={15}
                    color="white"
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    data-testid="check-icon"
                  />
                </OuterCircle>
              </Square>
            ) : (
              <Square data-testid="square">
                <OuterCircle bg="gray.400" data-testid="outer-circle"  />
              </Square>
            )}
            <AnimatePresence>
              {!isLastStep && (
                <Flex pos="relative" flexDirection="row" justifyContent="center" flex={1}>
                  <SpacerLine
                    data-testid="spacer-line"
                    bg={programme.progress === 100 ? 'green.300' : 'gray.300'}
                    className="test-spacer"
                    exit={{ height: 0 }}
                    initial={{ height: 0 }}
                    animate={{ height: programme.height }}
                  />
                </Flex>
              )}
            </AnimatePresence>
          </React.Fragment>
        )
      })}
    </Flex>
  )
}

export default TimelineCheck
