import { CircularProgress, CircularProgressLabel, Icon, Stack, Text } from '@chakra-ui/core'
import dayjs from 'dayjs'
import React, { useMemo } from 'react'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { Link, generatePath, useLocation } from 'react-router-dom'
import { H2, H3 } from '../../typography'
import MotionFlex from '../MotionFlex'
import { useFeedforwardStats, useThree60SurveyStats } from '../../hooks'
import { User } from 'phosphor-react'

type Programme = {
  id: string | number
  name: string
  submodules: number
  progress: number
  start: string
  three60formId?: string
  userId?: number
  section?: number,
  submodsubmission?: number
  feedfoward_url_id?: number
}

type ProgrammeItemProps = {
  programme: Programme
}

const ProgrammeItem: React.FC<ProgrammeItemProps> = ({ programme }) => {
  const {
    three60formId,
    userId,
    section,
    submodsubmission,
    feedfoward_url_id
  } = programme
  
  const { pathname } = useLocation()
  const feedforwardId = useMemo(() => {
    if (pathname === '/auth/dashboard' || pathname === '/auth/programmes') {
      return feedfoward_url_id
    }
    return undefined
  }, [pathname, feedfoward_url_id])

  const {
    totalResponses
  } = useThree60SurveyStats(
    three60formId,
    userId,
    section,
    submodsubmission
  )

  const { feedforwardTotalResponses } = useFeedforwardStats(feedforwardId)

  return (
    <MotionFlex
      flexDir="column"
      alignItems="start"
      w={{
        xs: '100%',
        sm: '330px'
      }}
      h="150px"
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.2)"
      borderRadius="10px"
      px="16px"
      mr={{
        xs: 0,
        sm: '15px'
      }}
      mb="24px"
      justifyContent="space-between"
    >
      <MotionFlex
        flexDir="row"
        alignItems="start"
        justifyContent="space-between"
        w="100%"
        mt="16px"
      >
        <MotionFlex flexDir="column" w="84%">
          <Link to={generatePath('/auth/programmes/:programmeId', { programmeId: programme.id })}>
            <H2 fontWeight={600} color="#115264" fontSize="16px" data-testid="programme-name">
              {programme.name}
            </H2>
          </Link>
          {(() => {
            if (totalResponses > -1 || feedforwardTotalResponses > -1) {
              return (
                <H2 fontWeight={400} color="#115264" fontSize="14px" fontStyle="normal" lineHeight="20px">
                  {programme.submodules} Modules
                </H2>
              )
            }
          })()}
        </MotionFlex>
        <MotionFlex>
          <CircularProgress
            value={programme.progress}
            h="51px"
            w="51px"
            color={programme.progress === 100 ? 'green' : 'yellow'}
          >
            <CircularProgressLabel>{programme.progress}%</CircularProgressLabel>
          </CircularProgress>
        </MotionFlex>
      </MotionFlex>
      <Stack w='100%'>
        {(() => {
          if ((programme?.three60formId || programme.feedfoward_url_id) && (totalResponses > -1 || feedforwardTotalResponses > -1)) {
            return (
              <Stack backgroundColor='red' w='100%' flexDirection='row'>
                {totalResponses > -1 ? (
                  <Stack spacing={0}>
                    <H3 fontWeight={400} color="#115264" fontSize="14px" fontStyle="normal" lineHeight="20px">
                      360 Survey:
                    </H3>
                    <Stack flexDirection='row' color='red'>
                      <User size={24} color='red'/>
                      <Stack p={1} />
                      <Text color='red'>
                        {totalResponses}
                      </Text>
                    </Stack>
                  </Stack>
                ) : null}
                <Stack p={1} />
                {feedforwardTotalResponses > -1 ? (
                  <Stack spacing={0}>
                    <H3 fontWeight={400} color="#115264" fontSize="14px" fontStyle="normal" lineHeight="20px">
                      Feedforward:
                    </H3>
                    <Stack flexDirection='row' color='red'>
                      <User size={24} color='red'/>
                      <Stack p={1} />
                      <Text color='red'>
                        {feedforwardTotalResponses}
                      </Text>
                    </Stack>
                  </Stack>
                ) : null}
              </Stack>
            )
          }
          return (
            <H2 fontWeight={400} color="#115264" fontSize="14px" fontStyle="normal" lineHeight="20px">
              {programme.submodules} Modules
            </H2>
          )
        })()}
      </Stack>
      <Stack w='100%'>

      </Stack>
      <MotionFlex
        flexDir="row"
        alignItems="start"
        justifyContent="space-between"
        w="100%"
        mb="12px"
      >
        <MotionFlex flexDir="column" w="84%">
          <H2 fontWeight={400} color="#989898" fontSize="14px" fontStyle="normal">
            Start date: {dayjs(programme.start).format('YYYY / MM / DD')}
          </H2>
        </MotionFlex>
        <MotionFlex w="16%" justifyContent="end">
          <Link to={generatePath('/auth/programmes/:programmeId', { programmeId: programme.id })}>
            <Icon
              as={MdKeyboardArrowRight}
              color="#2F2F2F"
              fontWeight={900}
              size="24px"
              fontStyle="bold"
            />
          </Link>
        </MotionFlex>
      </MotionFlex>
    </MotionFlex>
  )
}

export default ProgrammeItem
