import { Flex } from '@chakra-ui/core'
import styled from '@emotion/styled'
import * as React from 'react'

const StyledVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
`

type VideoCardProps = {
  video: any
}

const VideoCard: React.FC<VideoCardProps> = ({ video }) => {
  const playerRef = React.createRef<HTMLVideoElement>()

  React.useEffect(() => {
    if (playerRef && playerRef.current) {
      playerRef.current.src = `${video?.url}` || ''
    }
  }, [playerRef, video])

  return (
    <Flex mb={2} height={[200, 400]} width='100%' pos="relative">
      <StyledVideo preload="auto" ref={playerRef} controls={true} className="video-js" />
    </Flex>
  )
}

export default React.memo(VideoCard)
